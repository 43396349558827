import { CLEAR_ERROR, USER } from "../constants/actionsTypes";

export default function(state = {}, action) {

  switch (action.type) {
    case USER.LOGIN.FETCH:
      return {...state, isLoading: action.loading};

    case USER.LOGIN.SUCCESS:
      return {...state, user: action.user};

    case USER.LOGIN.FAILED:
      return {...state, error: action.error};

    case CLEAR_ERROR :
      return {...state, error: null};

    default:
      return state;


  }

}
