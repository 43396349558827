import React, { Component, Fragment } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Layout,
  Breadcrumb,
  Button,
  Modal,
  Input,
  Table,
  Alert,
  notification,
} from 'antd';
import Region from '../../../core/Region';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;

class RegionsPage extends Component {
  static propTypes = {
    regions: PropTypes.array.isRequired,
    errors: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,

    getRegions: PropTypes.func.isRequired,
    createRegion: PropTypes.func.isRequired,
    deleteRegion: PropTypes.func.isRequired,
    updateRegion: PropTypes.func.isRequired,

    error: PropTypes.string,
    clearError: PropTypes.func.isRequired,
  };

  regionModel = [
    {
      title: '# / Code',
      dataIndex: 'code',
      render: (v) => <Fragment>{v}</Fragment>,
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a, b) => a.length - b.length,
    },
    {
      title: "Entreprise d'attache",
      dataIndex: 'enterprise',
      render: (v) => <Fragment>{v.name}</Fragment>,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (region) => (
        <React.Fragment>
          <Button
            icon={<EditOutlined />}
            type={'primary'}
            style={{ marginRight: 12 }}
            onClick={() => {
              this.setState({
                currentRegion: region,
                currentRegionAction: 'UPDATE',
                addRegionModalOpened: true,
              });
            }}
          >
            {' '}
            Editer{' '}
          </Button>
          <Button
            icon={<DeleteOutlined />}
            type={'danger'}
            onClick={() => {
              this.setState(
                {
                  currentRegion: region,
                  currentRegionAction: 'DELETE',
                },
                () => this.deleteRegion(),
              );
            }}
          >
            {' '}
            Supprimer{' '}
          </Button>
        </React.Fragment>
      ),
    },
  ];

  state = {
    addRegionModalOpened: false,
    regions: [],
    currentRegion: new Region(),
    currentRegionAction: '',
    isLoading: false,
    checkCodeResponse: 0,
  };

  componentDidMount() {
    this.props.getRegions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      notification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });

      this.props.clearError();
    } else if (this.props.regions !== nextProps.regions) {
      let description = null;

      switch (this.state.currentRegionAction) {
        case 'CREATE':
          description = `Region créée avec succès`;
          break;
        case 'UPDATE':
          description = `Region mis à jour avec succès`;
          break;
        case 'DELETE':
          description = `Region supprimée avec succès`;
          break;
        default:
          break;
      }

      if (description)
        notification.success({
          message: 'Action effectuée !  👌🏻',
          description,
        });

      this.setState({
        regions: _.cloneDeep(nextProps.regions),
        addRegionModalOpened: false,
        currentRegionAction: '',
      });
    } else if (this.props.isLoading !== nextProps.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }
  }

  /* Region */

  createOrUpdateRegion() {
    switch (this.state.currentRegionAction) {
      case 'CREATE':
        this.props.createRegion(this.state.currentRegion);
        break;
      case 'UPDATE':
        this.props.updateRegion(this.state.currentRegion);
        break;
    }
  }

  deleteRegion() {
    this.props.deleteRegion(this.state.currentRegion);
  }

  async changeRegionInput(type, value) {
    const currentRegion = this.state.currentRegion;
    switch (type) {
      case 'NAME':
        currentRegion.name = value;
        break;
      case 'CODE':
        currentRegion.code = value;
        break;
      case 'CODE_CHECK':
        const code = await Region.checkCode(value);
        this.setState({ checkCodeResponse: code });
        break;
    }

    this.setState({
      currentRegion,
    });
  }

  regionModal() {
    const color =
      this.state.checkCodeResponse === 200
        ? '#af0011'
        : this.state.checkCodeResponse === 404
        ? '#189644'
        : '#b0b0b0';

    return (
      <Modal
        centered
        title={'Ajouter une région'}
        okText={
          this.state.currentRegionAction === 'CREATE'
            ? 'Ajouter la région'
            : 'Modifier la région'
        }
        cancelText={'Annuler'}
        visible={this.state.addRegionModalOpened}
        onCancel={() =>
          this.setState({
            addRegionModalOpened: !this.state.addRegionModalOpened,
          })
        }
        onOk={() => this.createOrUpdateRegion()}
        confirmLoading={this.state.isLoading}
      >
        <Input
          placeholder={'Nom de la région'}
          maxLength={30}
          style={{ marginBottom: 12 }}
          value={this.state.currentRegion.name}
          onChange={(e) => this.changeRegionInput('NAME', e.target.value)}
        />
        <Input
          placeholder={'Code région unique'}
          style={{ borderColor: color, borderWidth: 1, color }}
          maxLength={5}
          value={this.state.currentRegion.code}
          onChange={(e) => this.changeRegionInput('CODE', e.target.value)}
          onBlur={(e) => {
            this.changeRegionInput('CODE_CHECK', e.target.value);
          }}
        />
        <span style={{ color }}>
          {this.state.checkCodeResponse === 200
            ? 'Ce code est déjà utilisé'
            : this.state.checkCodeResponse === 404
            ? 'Ce code région est disponible'
            : ''}
        </span>
      </Modal>
    );
  }

  regionsJSX() {
    return (
      <Fragment>
        <h1>Régions</h1>

        <Button
          style={{ marginBottom: 24 }}
          icon={<PlusOutlined />}
          onClick={() =>
            this.setState({
              addRegionModalOpened: !this.state.addRegionModalOpened,
              currentRegion: new Region(),
              currentRegionAction: 'CREATE',
            })
          }
        >
          Nouvelle région
        </Button>

        <Alert
          message={
            <Fragment>
              La suppression d'une région entraîne{' '}
              <strong>
                la suppression des agences attenantes & des utilisateurs
                attachés
              </strong>
              .
            </Fragment>
          }
          type="warning"
          showIcon
        />
        <Table
          columns={this.regionModel}
          rowKey={'id'}
          dataSource={this.state.regions}
        />
      </Fragment>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Agences & Regions</Breadcrumb.Item>
          <Breadcrumb.Item>Régions </Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          {this.regionsJSX()}
        </div>
        {this.regionModal()}
      </Content>
    );
  }
}

export default RegionsPage;
