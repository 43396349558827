import React, { Component } from "react";
import { connect } from "react-redux";
import RegionsPage from "../components/Views/Regions";
import { listRegions, createRegion, deleteRegion, updateRegion } from "../actions/regions.actions";
import {clearErrors} from "../actions/error.action";


class RegionsContainer extends Component {
  render() {
    return (
      <RegionsPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    regions: store.regions.data,
    error: store.regions.error,
    isLoading: store.regions.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    getRegions: () => dispatch(listRegions()),
    createRegion: (region) => dispatch(createRegion(region)),
    updateRegion: (region) => dispatch(updateRegion(region)),
    deleteRegion: (region) => dispatch(deleteRegion(region)),
    clearError: () => dispatch(clearErrors())

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionsContainer);
