import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentsPage from "../components/Views/Documents";
import {fetchDocuments, createDocument, updateDocument, deleteDocument} from "../actions/documents.actions";
import {clearErrors} from "../actions/error.action";

class DocumentsContainer extends Component {
  render() {
    return (
      <DocumentsPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    documents: store.documents.data,
    error: store.documents.error,
    isLoading: store.documents.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocuments: () => dispatch(fetchDocuments()),
    createDocument: (document) => dispatch(createDocument(document)),
    updateDocument: (document) => dispatch(updateDocument(document)),
    deleteDocument: (document) => dispatch(deleteDocument(document)),
    clearError: () => dispatch(clearErrors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsContainer);
