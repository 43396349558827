import React, { Component, FC } from 'react';
import PropTypes from 'prop-types';
import { Layout, Input, Button, Alert } from 'antd';
import qs from 'qs';

import './Authentication.sass';
import logoApp from '../../../assets/logo_round.png';
import logoDBcall from '../../../assets/DBCall.png';
import logoCustomer from '../../../assets/customer@3x.png';
import User from '../../../core/User';

const { Content } = Layout;

interface AuthenticationProps {
  location: any;
  history: any;
  login: () => void;
  error?: any;
  user?: User;
  isLoading: boolean;
}

class Authentication extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.object,
    user: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      user: new User(),
    };

    this.login = this.login.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search.slice(1));
    const error = query.error || null;
    this.setState({ error });

    if (query.disconnected) {
      this.setState({
        success: 'Vous avez été déconnecté avec succès ! A bientôt. ',
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.error !== nextProps.error) {
      this.setState({ error: nextProps.error });
    }

    if (this.props.user !== nextProps.user) {
      this.setState({ user: nextProps.user });
      this.props.history.push('/dashboard');
    }

    if (this.props.isLoading !== nextProps.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }
  }

  login(e) {
    e.preventDefault;
    this.setState({ isLoading: true });

    if (!this.state.user.email) {
      this.setState({ error: 'noEmail', isLoading: false });
    } else if (!this.state.user.password) {
      this.setState({ error: 'noPassword', isLoading: false });
    } else {
      this.setState({ error: null });
    }

    this.setState({ isLoading: false });
    this.props.login(this.state.user);
  }

  changeInput(input, value) {
    const user = this.state.user;

    switch (input) {
      case 'EMAIL':
        user.email = value;
        break;

      case 'PASSWORD':
        user.password = value;
        break;
    }

    this.setState({ user });
  }

  renderError() {
    if (this.state.error) {
      let message = '';

      switch (this.state.error) {
        case 'notAuthorized':
          message =
            "Vous n'êtes pas authentifié, impossible d'accéder au dashboard";
          break;

        case 'notExpert':
          message =
            "Vous n'avez ni le statut d'expert, ni de super-expert, impossible de vous connecter à cette interface.";
          break;

        case 'noEmail':
          message = 'Impossible de vous connecter sans votre email.';
          break;

        case 'noPassword':
          message = 'Le champ mot de passe est vide, tête de nœuds !';
          break;

        default:
          message = this.state.error;
          break;
      }

      return (
        <Alert
          message={'Erreur'}
          description={message}
          type="error"
          className={'ErrorMessage'}
          showIcon
          closable
        />
      );
    }
  }

  renderSuccess() {
    if (this.state.success) {
      return (
        <Alert
          message={this.state.success}
          type="success"
          className={'ErrorMessage'}
          showIcon
          closable
        />
      );
    }
  }

  render() {
    return (
      <Layout className={'Authentication'}>
        <Content className={'Content'}>
          <div>
            <h1>Connexion</h1>
            {this.renderError()}
            {this.renderSuccess()}
            <div className={'Form'}>
              <img
                src={logoApp}
                align="Logo App QHSE"
                className={'Authentication__Logo'}
              />
              <form>
                <Input
                  type={'text'}
                  placeholder={'Email'}
                  className={'Input'}
                  value={this.state.user.email}
                  onChange={(e) => this.changeInput('EMAIL', e.target.value)}
                />
                <Input
                  type={'password'}
                  value={this.state.user.password}
                  placeholder={'Mot de passe'}
                  className={'Input'}
                  onChange={(e) => this.changeInput('PASSWORD', e.target.value)}
                />
                <Button
                  type={'primary'}
                  onClick={(e) => this.login(e)}
                  loading={this.state.isLoading}
                  className={'Button'}
                >
                  {' '}
                  Connexion{' '}
                </Button>
              </form>
            </div>
            <img
              src={logoCustomer}
              align="Logo Customer"
              className={'Customer__Logo'}
            />
          </div>
        </Content>
        <div className={'Footer'}>
          <span>© 2015-2018 - DBCall</span>
          <img src={logoDBcall} className={'Footer__Logo'} />
        </div>
      </Layout>
    );
  }
}

export default Authentication;
