import EPIAPI from "./API/EPI.api";
import moment from "moment";

export default class EPI {


  constructor() {
    this.id = 0;
    this.designation = '';
    this.verifiable = false;
    this.createdAt = moment();
    this.updatedAt = moment();
  }

  static async getAll() {

    try {
      const epis = await EPIAPI.read();
      return epis.map(e => Object.assign(new EPI(), e, {createdAt: moment(e.createdAt), updatedAt: moment(e.updatedAt), verifiable: e.is_verifiable}));

    } catch(error) {
      throw error;
    }

  }


  static async getEquipment() {

    try {
      return await EPIAPI.getEquipments();


    } catch(error) {
      throw error;
    }

  }

  async create() {
    try {
      const epi = await EPIAPI.create(this);
      return Object.assign(new EPI(), epi);

    } catch(error) {
      throw error;
    }
  }

  async update() {
    const epi = await EPIAPI.update(this);
    return Object.assign(new EPI(), epi, {createdAt: moment(epi.createdAt), updatedAt: moment(epi.updatedAt), verifiable: epi.is_verifiable});
  }

  async delete() {
    await EPIAPI.delete(this);
  }
}
