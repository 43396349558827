import {DOCUMENTS} from "../constants/actionsTypes";
import Documents from "../core/Documents";

const fetch = (isLoading) => {
    return {
        type: DOCUMENTS.FETCH,
        isLoading
    }
};

/* Fetch News */
const getDocumentsWithSuccess = (documents) => {
    return {
        type: DOCUMENTS.LIST.SUCCESS,
        documents
    }
};

const getDocumentsWithError = (error) => {
  return {
    type: DOCUMENTS.LIST.FAILED,
    error
  }
};

const fetchDocuments = (mostRead = false) => {
  return async (dispatch) => {

    try {
      const documents = await Documents.getAll(mostRead);
      dispatch(getDocumentsWithSuccess(documents))
    } catch(error) {
      dispatch(getDocumentsWithError(error.message));
    } finally {
      dispatch(fetch(false));
    }

  }
};

/* Create News */

const createDocumentWithSuccess = (document) => {
    return {
        type: DOCUMENTS.CREATE.SUCCESS,
        document
    }
};

const createDocumentWithFailure = (error) => {
  return {
    type: DOCUMENTS.CREATE.FAILED,
    error
  }
};

const createDocument = (news) => {

  return async (dispatch) => {
    dispatch(fetch(true));
    try {
      const createdNews = await news.create();
      dispatch(createDocumentWithSuccess(createdNews));
    } catch(error) {

      dispatch(createDocumentWithFailure(error.message));
    } finally {
      dispatch(fetch(false));
    }
  }

};

/* Update News */

const updateDocumentWithSuccess = (news) => {
  return {
    type: DOCUMENTS.UPDATE.SUCCESS,
    news
  }
};

const updateDocumentWithFailure = (error) => {
  return {
    type: DOCUMENTS.UPDATE.FAILED,
    error
  }
};

const updateDocument = (document) => {

  return async dispatch => {

    dispatch(fetch(true));

    try {
      const updatedNews = await document.update();
      dispatch(updateDocumentWithSuccess(updatedNews));
    } catch(error) {
      dispatch(updateDocumentWithFailure(error.message));
    } finally {
      dispatch(fetch(false));
    }

  }

};

/* Delete News */

const deleteDocumentsWithSuccess = (documentId) => {
  return {
    type: DOCUMENTS.DELETE.SUCCESS,
    documentId
  }
};

const deleteDocumentWithError = (error) => {
  return {
    type: DOCUMENTS.DELETE.FAILED,
    error
  }
};

const deleteDocument = (document) => {
  return async (dispatch) => {

    dispatch(fetch(true));

    try {
      await document.delete();
      dispatch(deleteDocumentsWithSuccess(document.id))
    } catch(error) {
      dispatch(deleteDocumentWithError(error.message))
    } finally {
      dispatch(fetch(false));
    }

  }
};

export {fetchDocuments, deleteDocument, createDocument, updateDocument}
