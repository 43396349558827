import { CLEAR_ERROR, EPI } from "../constants/actionsTypes";
import * as _ from "lodash";


export default function(state = {}, action) {

  const data = _.cloneDeep(state.data);

  switch (action.type) {

    case EPI.FETCH:
      return {...state, isLoading: action.isLoading};

    case EPI.LIST.SUCCESS:
      return {...state, error: null, data: action.epis};

    case EPI.DELETE.SUCCESS:
      _.remove(data, {id: action.epiId});
      return {...state, error:null, data};

    case EPI.CREATE.SUCCESS:
      data.push(action.epi);
      return {...state, error:null, data};

    case EPI.UPDATE.SUCCESS:

      _.remove(data, {id: action.epi.id});
      data.push(action.epi);

      return {...state, error:null, data};

    case EPI.LIST.FAILED:
    case EPI.DELETE.FAILED:
    case EPI.CREATE.FAILED:
    case EPI.UPDATE.FAILED:
      return {...state, error:action.error, data: []};
    case CLEAR_ERROR :
      return {...state, error: null};

    default:
      return state;
  }

}
