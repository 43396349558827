import {
  cloneDeep,
  remove,
} from 'lodash';
import { CLEAR_ERROR, NEWS } from '../constants/actionsTypes';

export default function (state = {}, action) {
  const data = cloneDeep(state.data);

  switch (action.type) {
    case NEWS.FETCH:
      return { ...state, isLoading: action.isLoading };

    case NEWS.LIST.SUCCESS:
      return { ...state, error: null, data: action.news };

    case NEWS.DELETE.SUCCESS:
      remove(data, { id: action.newsId });
      return { ...state, error: null, data };

    case NEWS.CREATE.SUCCESS:
      data.push(action.news);
      return { ...state, error: null, data };

    case NEWS.UPDATE.SUCCESS:
      remove(data, { id: action.news.id });
      data.push(action.news);

      return { ...state, error: null, data };

    case NEWS.LIST.FAILED:
    case NEWS.DELETE.FAILED:
    case NEWS.CREATE.FAILED:
    case NEWS.UPDATE.FAILED:
      return { ...state, error: action.error, data: [] };
    case CLEAR_ERROR:
      return { ...state, error: null };

    default:
      return state;
  }
}
