import {NEWS} from "../constants/actionsTypes";
import News from "../core/News";

const fetch = (isLoading) => {
    return {
        type: NEWS.FETCH,
        isLoading
    }
};

/* Fetch News */
const getNewsWithSuccess = (news) => {
    return {
        type: NEWS.LIST.SUCCESS,
        news
    }
};

const getNewsWithError = (error) => {
  return {
    type: NEWS.LIST.FAILED,
    error
  }
};

const fetchNews = () => {
  return async (dispatch) => {

    try {
      const fetchedNews = await News.getAll();
      dispatch(getNewsWithSuccess(fetchedNews))
    } catch(error) {
      dispatch(getNewsWithError(error.message));
    } finally {
      dispatch(fetch(false));
    }

  }
};

/* Create News */

const createNewsWithSuccess = (news) => {
    return {
        type: NEWS.CREATE.SUCCESS,
        news
    }
};

const createNewsWithFailure = (error) => {
  return {
    type: NEWS.CREATE.FAILED,
    error
  }
};

const createNews = (news) => {

  return async (dispatch) => {
    dispatch(fetch(true));
    try {
      const createdNews = await news.create();
      dispatch(createNewsWithSuccess(createdNews));
    } catch(error) {

      dispatch(createNewsWithFailure(error.message));
    } finally {
      dispatch(fetch(false));
    }
  }

};

/* Update News */

const updateNewsWithSuccess = (news) => {
  return {
    type: NEWS.UPDATE.SUCCESS,
    news
  }
};

const updateNewsWithFailure = (error) => {
  return {
    type: NEWS.UPDATE.FAILED,
    error
  }
};

const updateNews = (news) => {

  return async dispatch => {

    dispatch(fetch(true));

    try {
      const updatedNews = await news.update();
      dispatch(updateNewsWithSuccess(updatedNews));
    } catch(error) {
      dispatch(updateNewsWithFailure(error.message));
    } finally {
      dispatch(fetch(false));
    }

  }

};

/* Delete News */

const deleteNewsWithSuccess = (newsId) => {
  return {
    type: NEWS.DELETE.SUCCESS,
    newsId
  }
};

const deleteNewsWithError = (error) => {
  return {
    type: NEWS.DELETE.FAILED,
    error
  }
};

const deleteNews = (news) => {
  return async (dispatch) => {

    dispatch(fetch(true));

    try {
      await news.delete();
      dispatch(deleteNewsWithSuccess(news.id))
    } catch(error) {
      dispatch(deleteNewsWithError(error.message))
    } finally {
      dispatch(fetch(false));
    }

  }
};

export {fetchNews, deleteNews, createNews, updateNews}
