import {Instance} from "./base";

export default class DocumentAPI {

  static async create(document, sendPush) {
    try {
      const response = await Instance(true).request.post('/v2/documents', {
        docu_domain: document.domain,
        docu_content: document.content,
        docu_title: document.title,
        docu_url: document.url,
        docu_send_push: sendPush,
        docu_tags: document.tags
      });
      return response.data.data;

    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

  static async readAll(readCount = false) {
    try {
      const response = await Instance(true).request.get(`/v2/documents/enterprise${readCount ? '?most_read=true' : ''}`);
      return response.data.data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

  static async update(document) {
    try {
      const response = await Instance(true).request.put(`/v2/documents/${document.id}`, {
        docu_domain: document.domain,
        docu_content: document.content,
        docu_title: document.title,
        docu_url: document.url,
        docu_tags: document.tags
      });
      return response.data.data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

  static async delete(document) {
    try {
      await Instance(true).request.delete(`/v2/documents/${document.id}`);
      return true
    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

}
