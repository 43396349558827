// Router + Redux setup
import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from '../App';

export default ({
  store,
  history,
}: {
  store: any;
  history: any;
}): JSX.Element => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  );
};
