import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
//require('./favicon.ico'); // Tell webpack to load favicon.ico
import 'antd/dist/antd.css';
import './styles/style.sass';
import initialStore from './store/initialStore';

const store = configureStore(initialStore);

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app'),
);
