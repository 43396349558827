import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Breadcrumb,
  Button,
  Modal,
  Input,
  Select,
  Alert,
  Divider,
  notification as AntNotification,
} from 'antd';
import Notification from '../../../core/Notification';
import { PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;

export default class NotificationsPage extends Component {
  static propTypes = {
    getAgencies: PropTypes.func.isRequired,
    agencies: PropTypes.array,
    error: PropTypes.string,
    clearError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      agencies: [],
      currentNotification: new Notification(null, null, null, null, null),
      isLoading: false,
    };

    this.notificationModal = this.notificationModal.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
    this.textArea = null;
  }

  componentDidMount() {
    this.props.getAgencies();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      AntNotification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });
      this.props.clearError();
    } else if (nextProps.agencies !== this.props.agencies) {
      this.setState({ agencies: nextProps.agencies });
    }
  }

  changeInput(type, value) {
    const currentNotification = this.state.currentNotification;

    switch (type) {
      case 'TITLE':
        currentNotification.title = value;
        break;

      case 'BODY':
        currentNotification.body = value;

        break;

      case 'AGENCY':
        currentNotification.agency = value;
        break;

      case 'TARGET':
        currentNotification.target = value;
        break;

      case 'BODY_FIRSTNAME':
        currentNotification.body += `{{user.firstname}} `;
        this.textArea.focus();
        break;

      case 'BODY_LASTNAME':
        currentNotification.body += `{{user.lastname}} `;
        this.textArea.focus();
        break;

      case 'BODY_AGENCY':
        currentNotification.body += `{{user.agency.name}} `;
        this.textArea.focus();
        break;

      case 'BODY_REGION':
        currentNotification.body += `{{user.agency.region.name}} `;
        this.textArea.focus();
        break;

      default:
        break;
    }

    this.setState({ currentNotification });
  }

  async sendNotification() {
    try {
      const notificationResponse = await this.state.currentNotification.send();

      this.setState(
        { currentNotification: new Notification(), modalVisible: false },
        () => {
          AntNotification.success({
            message: `Notification envoyée avec succès à ${notificationResponse.sent} utilisateurs`,
          });
        },
      );
    } catch (e) {
      AntNotification.error({
        message: `Erreur ${e.response.status}`,
        description: `${e.response.data.error}`,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  notificationModal() {
    return (
      <Modal
        title={'Nouvelle notification'}
        visible={this.state.modalVisible}
        onCancel={() =>
          this.setState({ modalVisible: !this.state.modalVisible })
        }
        onOk={() => {
          this.setState({ isLoading: true }, () => this.sendNotification());
        }}
        okText={'Envoyer la notification'}
        cancelText={'Annuler'}
        confirmLoading={this.state.isLoading}
      >
        <Alert
          showIcon
          message={
            <span>
              Vous pouvez utiliser les boutons ci-dessous, ils seront remplacés
              par les valeurs associés aux destinataires des notifications.
            </span>
          }
          style={{ marginBottom: 12 }}
        />

        <Input
          maxLength={40}
          placeholder={'Titre de votre notification'}
          style={{ marginBottom: 12 }}
          value={this.state.currentNotification.title}
          onChange={(e) => this.changeInput('TITLE', e.target.value)}
        />
        <TextArea
          maxLength={200}
          ref={(ref) => (this.textArea = ref)}
          placeholder={'Corps de la notification'}
          style={{ marginBottom: 12 }}
          value={this.state.currentNotification.body}
          onChange={(e) => this.changeInput('BODY', e.target.value)}
        />
        <Button.Group>
          <Button
            size={'small'}
            htmlType={'button'}
            onClick={() => this.changeInput('BODY_FIRSTNAME')}
          >
            Prénom{' '}
          </Button>
          <Button
            size={'small'}
            htmlType={'button'}
            onClick={() => this.changeInput('BODY_LASTNAME')}
          >
            Nom{' '}
          </Button>
          <Button
            size={'small'}
            htmlType={'button'}
            onClick={() => this.changeInput('BODY_AGENCY')}
          >
            Agence
          </Button>
          <Button
            size={'small'}
            htmlType={'button'}
            onClick={() => this.changeInput('BODY_REGION')}
          >
            Region
          </Button>
        </Button.Group>
        <Divider />

        <Select
          placeholder={'Agences'}
          defaultValue={null}
          style={{ width: 240 }}
        >
          <Select.Option key={'All'} value={null}>
            Toutes les agences
          </Select.Option>
          {this.state.agencies.map((a) => (
            <Select.Option key={a.code} value={a.code}>
              {a.name}
            </Select.Option>
          ))}
        </Select>

        <Select defaultValue={'ALL'} style={{ marginLeft: 12 }}>
          <Option value={'ALL'}>Tout les profils</Option>
          <Option value={'TEC'}>Techniciens</Option>
          <Option value={'REF'}>Référents</Option>
          <Option value={'EXP'}>Experts</Option>
          <Option value={'SUP'}>Super-expert</Option>
          <Option value={'DRE'}>Directeurs régionaux</Option>
        </Select>
      </Modal>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Notifications</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <h1>Notifications</h1>
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              this.setState({ modalVisible: !this.state.modalVisible })
            }
          >
            Nouvelle Notification
          </Button>
        </div>
        {this.notificationModal()}
      </Content>
    );
  }
}
