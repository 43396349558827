import IndicatorAPI from "./API/Indicator.api";
import moment from "moment";
import Region from "./Region";

export default class Indicator {

  constructor(id, name, key, lastOccurrence, region) {
    this.id = id;
    this.name = name;
    this.key = key;
    this.lastOccurrence = moment(lastOccurrence);
    if(typeof region === typeof {}) {
      this.region = new Region(region.id, region.code, region.name, region.enterprise);
      this.regionCode = this.region.code;
    } else {
      this.regionCode = region;
      this.region = new Region();
      this.region.code = region;
    }


  }

  async create() {

    try {
      const indicator = await IndicatorAPI.create(this);
      this.id = indicator.id;
      this.region = indicator.region;
      return this;

    } catch(error) {

      throw error;
    }

  }

  async update() {

    try {
      const indicator = await IndicatorAPI.update(this);
      this.id = indicator.id;
      this.region = indicator.region;
      this.lastOccurrence = moment(indicator.last_occurence);
      return this;

    } catch(error) {

      throw error;
    }

  }

  async delete() {
    try {
      await IndicatorAPI.delete(this);
      return true;

    } catch(error) {

      throw error;
    }
  }

  static list() {
    return [
      'IND_CAR_CRASH',
      'IND_ROT_SPRAY',
      'IND_ROT_BATTERY',
      'IND_WORK_ACCIDENT',
      'IND_WORK_EPIORDER',
      'IND_WORK_SECURITY_TRAINING',
      'IND_OTHER',
    ]
  }

  static getName(key, fallBack) {

    switch (key) {
      case 'IND_CAR_CRASH': return 'Dernier sinistre routier';
      case 'IND_ROT_SPRAY': return 'Dernière rotation bac à aérosols';
      case 'IND_ROT_BATTERY': return 'Dernière rotation bac à batteries';
      case 'IND_WORK_ACCIDENT': return 'Dernier accident du travail';
      case 'IND_WORK_EPIORDER': return 'Dernière commande d\'EPIs';
      case 'IND_WORK_SECURITY_TRAINING': return 'Dernière formation de sécurité';
      default: return fallBack;
    }

  }

  static getIcon(key) {

    switch (key) {
      case 'IND_CAR_CRASH': return require('../assets/indicators/indicators-accidents.svg').default;
      case 'IND_ROT_SPRAY': return require('../assets/indicators/indicator-aerosol.svg').default;
      case 'IND_ROT_BATTERY': return require('../assets/indicators/indicator-battery.svg').default;
      case 'IND_WORK_ACCIDENT': return require('../assets/indicators/indicators-bandage.svg').default;
      case 'IND_WORK_EPIORDER': return require('../assets/indicators/indicator-epi-order.svg').default;
      case 'IND_WORK_SECURITY_TRAINING': return require('../assets/indicators/indicator-training.svg').default;
      default: return require('../assets/indicators/indicator-unknown.svg').default;
    }

  }

  static async getForEnterprise() {

    try {
      const indicators = await IndicatorAPI.getForEnterprise();
      return indicators.map(i => new Indicator(i.id, i.name, i.key, i.last_occurence, i.region))
    } catch(error) {

      throw error;
    }


  }

}

Indicator.fields = Object.freeze({id: 1, name: 2, code: 3, regionCode: 4, lastOccurence: 5});
