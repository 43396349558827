import {Instance} from "./base";

export default class NewsAPI {

  static async create(news) {
    try {
      const response = await Instance(true).request.post('/v2/news', {
        actu_domain: news.domain,
        actu_content: news.content,
        actu_send_push: news.sendPush
      });
      return response.data.data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

  static async readAll() {
    try {
      const response = await Instance(true).request.get('/v2/news');
      return response.data.data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

  static async update(news) {
    try {
      const response = await Instance(true).request.put(`/v2/news/${news.id}`, {
        actu_domain: news.domain,
        actu_content: news.content,
        actu_visible: news.visible,
      });
      return response.data.data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

  static async delete(news) {
    try {
      await Instance(true).request.delete(`/v2/news/${news.id}`);
      return true
    } catch (e) {
      throw new Error(e.response.data.error);
    }

  }

}
