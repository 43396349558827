import {EQUIPMENT as EQUIPMENTAction} from "../constants/actionsTypes";
import EPI from '../core/EPI'

const fetch = (isLoading) => {
  return {
    type: EQUIPMENTAction.FETCH,
    isLoading,
  }
};

const listSuccess = (equipments) => {
  return {
    type: EQUIPMENTAction.LIST.SUCCESS,
    equipments,
  }
};

const listError = (error) => {
  return {
    type: EQUIPMENTAction.LIST.FAILED,
    error,
  }
};

const listEquipments = () => {
  return async (dispatch) => {
    dispatch(fetch(true));

    try {
      const equipements = await EPI.getEquipment();
      dispatch(listSuccess(equipements));
    } catch(error) {
      dispatch(listError(error.message))
    } finally {
      dispatch(fetch(false));
    }
  }
};


export {listEquipments}

