import Agency from "./Agency";
import UserAPI from "./API/User.api";

export default class User {

  constructor() {
    this.firstname = '';
    this.lastname = '';

    this.email= '';
    this.phoneNumber= '';

    this.agency = new Agency();
    this.position = '';

    this.isDirecteur = false;
    this.isExpert = false;
    this.isReferent = false;
    this.pendingValidation = false;

    this.domains = [];

    this.inVacation = false;


    this.password = '';

    this.usePti = false;
    this.firstname = '';
    this.lastname = '';

    this.email= '';
    this.phoneNumber= '';

    this.agency = new Agency();
    this.position = '';

    this.isDirecteur = false;
    this.isExpert = false;
    this.isReferent = false;
    this.pendingValidation = false;

    this.domains = [];

    this.inVacation = false;


    this.password = '';

    this.usePti = false;
    this.uuid = '';
    this.installId = '';

    this.referents = [];
    this.epis = [];
    this.indicators = [];

  }

  async login() {

    try {
      const user = await UserAPI.login(this.email, this.password);
      window.localStorage.setItem('@DBCall:ApiToken', user.data.token);
      return user.data;
    } catch (e) {
      throw new Error(e.message);
    }

  }

  static async fetch() {

    const users = await UserAPI.getAll();
    return users.map(u => Object.assign(new User(), u));

  }

  async delete() {
    try {
      await UserAPI.delete(this);
      return true;
    } catch(error) {
      throw error;
    }
  }

  async promote(asDirector, asExpert) {
    try {
      await UserAPI.promote(this, asDirector, asExpert);
      return true
    } catch(error) {
      throw error;
    }
  }

}
