import axios from 'axios';

const CancelToken = axios.CancelToken;

const PROD = (process.env.NODE_ENV === 'production');

const Instance = (authenticated = false) => {

  let cancel;
  const apiToken = window.localStorage.getItem('@DBCall:ApiToken');

  const baseURL = process.env.API_URL
  let headers = {};

  if(authenticated && apiToken) {
    headers['Authorization'] = `Bearer ${apiToken}`;
  } else if(authenticated && !apiToken) {
    throw new Error('No API Token for performing request');
  }
  headers['X-Requester'] = 'DBCall-HSE';
  headers['X-API-Version'] = '2';
  headers['X-API-Language'] = 'FR';
  headers['X-API-Environment'] = PROD ? 'production' : 'development';


  const request = axios.create({
    baseURL,
    headers,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    })
  });

  return {request, cancel};

};

export {Instance};
