import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Avatar, Icon, ConfigProvider } from 'antd';
import UserOutlined, { LogoutOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import AppMenu from './Menu';
import './Application.sass';
const { Header, Footer } = Layout;

const customizeRenderEmpty = () => (
  <div align="center">
    <img
      alt={`No data`}
      src={require('../../../assets/icons/Synch.svg').default}
    />
    <br />
    <span>Chargement...</span>
  </div>
);

class GlobalLayout extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
  };

  render() {
    const user = `Administrateur`;
    return (
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Layout style={{ minHeight: '100vh' }}>
          <AppMenu />
          <Layout>
            <Header theme="dark">
              <Row>
                <Col span={5} offset={18} style={{ color: 'white' }}>
                  <Avatar
                    size="large"
                    icon={<UserOutlined />}
                    style={{ marginRight: 12 }}
                  />
                  {user}
                </Col>
                <Col span={1} style={{ color: 'white' }}>
                  <LogoutOutlined
                    style={{
                      fontSize: 20,
                      color: 'red',
                      position: 'relative',
                      top: 5,
                    }}
                    onClick={() => {
                      window.localStorage.removeItem('@DBCall:ApiToken');
                      this.props.history.push('/login?disconnected=true');
                    }}
                  />
                </Col>
              </Row>
            </Header>
            {this.props.children}
            <Footer style={{ textAlign: 'center' }}>
              © 2015 - 2021 - DBCall &{' '}
              <a href={'https://bitsncoffee.com'}>Bits'n Coffee</a>
            </Footer>
          </Layout>
        </Layout>
      </ConfigProvider>
    );
  }
}

export default withRouter(GlobalLayout);
