import React, { Component } from "react";
import { connect } from "react-redux";
import NotificationsPage from "../components/Views/Notifications";
import {listAgencies} from "../actions/agencies.actions";
import {clearErrors} from "../actions/error.action";


class NotificationsContainer extends Component {
  render() {
    return (
      <NotificationsPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    agencies: store.agencies.data,
    error: store.agencies.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAgencies: () => dispatch(listAgencies()),
    clearError: () => dispatch(clearErrors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
