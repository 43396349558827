import {Instance} from "./base";

export default class UserAPI {

  static async login(email, password) {
    try {
      const response = await Instance(false).request.patch('/v2/users/login?to_dashboard=true',{
        email: email,
        password: password
      });
      return response.data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }


  }

  static async getAll() {

    try {
      const response = await Instance(true).request.get(`/v2/users`);
      return response.data.data;
    } catch(error) {
      throw new Error(error.response.data.error);
    }


  }

  static async update(user) {
    return user;
  }

  static async promote(user, asDirector, asExpert) {
    await Instance(true).request.patch(`/v2/users/${user.email}/promote`, {
      as_director: asDirector,
      as_expert: asExpert,
      as_super_expert: false,
    });
    return true;

  }

  static async delete(user) {
    try {
      await Instance(true).request.delete(`/v2/users/${user.id}`);
      return true;
    } catch(error) {
      throw new Error(error.response.data.error);
    }
  }

}
