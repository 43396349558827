import NotificationAPI from "./API/Notification.api";

export default class Notification {

  constructor (title, body, data, agency, profile) {
    this.title = title;
    this.body = body;
    this.data = data;
    this.agency = agency;
    this.profile = profile;

  }

  async send() {

    try {
      return await NotificationAPI.post(this);
    } catch(error) {
      throw error;
    }

  }

}
