import {Instance} from "./base";

export default class NotificationAPI {

  static async post(notification) {
    try {
      const response = await Instance(true).request.post('/v2/notifications',{
        notification_title: notification.title,
        notification_body: notification.body,
        notification_target: notification.profile,
        notification_agency: notification.agency,
        notification_data: notification.data,
      });
      return response.data.data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }


  }

}
