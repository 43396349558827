import {REGIONS} from "../constants/actionsTypes";
import Region from "../core/Region";

const loadRegions = (isLoading) => {
  return {
    type: REGIONS.FETCH,
    isLoading
  }
};

const fetchRegionsSuccess = (regions) => {
  return {
    type: REGIONS.LIST.SUCCESS,
    regions
  }
};

const fetchRegionsFailure = (error) => {
  return {
    type: REGIONS.LIST.FAILED,
    error
  }
};


const listRegions = () => {
  return async (dispatch) => {

    dispatch(loadRegions(true));

    try {
      const regions = await Region.getAll();
      dispatch(fetchRegionsSuccess(regions));
    } catch(error) {
      dispatch(fetchRegionsFailure(error.message));
    } finally {
      dispatch(loadRegions(false));
    }
  }
};

/* CREATE REGION */

const createRegionSuccess = (region) => {
  return {
    type: REGIONS.CREATE.SUCCESS,
    region
  }
};

const createRegionFailure = (error) => {
  return {
    type: REGIONS.CREATE.FAILED,
    error
  }
};

const createRegion = (region) => {

  return async dispatch => {

    dispatch(loadRegions(true));
    try {
      const createdRegion = await region.create();
      dispatch(createRegionSuccess(createdRegion))
    } catch(error) {
      dispatch(createRegionFailure(error.message))
    } finally {
      dispatch(loadRegions(false));
    }

  }


};

/* UPDATE REGION */

const updateRegionSuccess = (region) => {
  return {
    type: REGIONS.UPDATE.SUCCESS,
    region
  }
};

const updateRegionFailure = (error) => {
  return {
    type: REGIONS.UPDATE.FAILED,
    error
  }
};

const updateRegion = (region) => {

  return async dispatch => {

    dispatch(loadRegions(true));
    try {
      const updatedRegion = await region.update();
      dispatch(updateRegionSuccess(updatedRegion))
    } catch(error) {
      dispatch(updateRegionFailure(error.message))
    } finally {
      dispatch(loadRegions(false));
    }

  }


};


/* DELERE REGION */

const deleteRegionSuccess = (regionId) => {
  return {
    type: REGIONS.DELETE.SUCCESS,
    regionId
  }
};

const deleteRegionFailure = (error) => {
  return {
    type: REGIONS.CREATE.FAILED,
    error
  }
};

const deleteRegion = (region) => {

  return async dispatch => {

    dispatch(loadRegions(true));
    try {
      await region.delete();
      dispatch(deleteRegionSuccess(region.id))
    } catch(error) {
      dispatch(deleteRegionFailure(error.message))
    } finally {
      dispatch(loadRegions(false));
    }

  }


};

export {listRegions, createRegion, deleteRegion, updateRegion}
