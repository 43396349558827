import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuth = () => {
    const jwtToken = window.localStorage.getItem('@DBCall:ApiToken');

    if (jwtToken) {
      const token = jwt.decode(jwtToken);
      const expiry = moment.unix(token.exp);
      return (
        (token.isExpert || token.isSuperExpert || token.isAdmin) &&
        moment().isBefore(expiry)
      );
    } else {
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
