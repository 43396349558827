import moment from "moment";
import DocumentAPI from "./API/Document.api";

export default class Document {

  constructor() {
    this.id = 0;
    this.title = '';
    this.content = '';
    this.author = '';
    this.url = null;
    this.tags = [];
    this.domain = undefined;
    this.created_at = moment();
    this.updated_at = moment();
    this.sendPush = true;
    this.readCount = 0;
  }

  static async getAll(mostRead = false) {
    const rawDocuments = await DocumentAPI.readAll(mostRead);
    return rawDocuments.map(d => Object.assign(new Document(), d));
  }

  async create() {
    const rawDocument = await DocumentAPI.create(this, this.sendPush);
    const document = Object.assign(new Document(), rawDocument, {id : parseInt(rawDocument.id)});
    return document;
  }

  async update() {
    const rawDocument = await DocumentAPI.update(this);
    const document = Object.assign(new Document(), rawDocument, {id : parseInt(rawDocument.id)});
    return document;
  }

  async delete() {
    try {
      await DocumentAPI.delete(this);
    } catch(error) {
      throw error;
    }

  }

}
