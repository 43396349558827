import RegionApi from "./API/Region.api";
import Enterprise from "./Enterprise";

export default class Region {

  constructor(id, code, name, enterprise) {
    this.id = id || undefined;
    this.code = code || undefined;
    this.name = name || undefined;
    if(enterprise) {
      this.enterprise = new Enterprise(enterprise.id, enterprise.name, enterprise.url);
    }
  }

  static async checkCode(code) {
    return await RegionApi.checkCode(code)
  }

  async create() {
    const createdRegion = await RegionApi.create(this);
    this.id = createdRegion.id;
    this.enterprise = new Enterprise(createdRegion.enterprise.id, createdRegion.enterprise.name, null);
    return this;
  }

  async update() {
    await RegionApi.update(this);
    return this;
  }

  async delete() {
    try {
      await RegionApi.delete(this);
    } catch(error) {
      throw error;
    }
  }

  static async getAll() {
    try {
      const rawRegions = await RegionApi.getAll();
      return rawRegions.map(rawRegion => new Region(rawRegion.id, rawRegion.code, rawRegion.name, rawRegion.enterprise));
    } catch(error) {
      throw error;
    }

  }

}
