import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  Layout,
  Breadcrumb,
  Button,
  Modal,
  Select,
  Input,
  Checkbox,
  Table,
  Switch,
  notification,
} from 'antd';
import Domain from '../../../core/Domain';
import News from '../../../core/News';
import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeInvisibleTwoTone,
  EyeOutlined,
  EyeTwoTone,
  PlusOutlined,
} from '@ant-design/icons';

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

class NewsPage extends Component {
  static propTypes = {
    news: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,

    fetchNews: PropTypes.func.isRequired,
    deleteNews: PropTypes.func.isRequired,
    updateNews: PropTypes.func.isRequired,
    createNews: PropTypes.func.isRequired,
    error: PropTypes.string,
    clearError: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
    currentAction: '',
    currentNews: new News(),
    isLoading: false,
    news: [],
    authorsFilter: [],
    tableModel: [
      {
        title: 'Domaine',
        dataIndex: 'domain',
        sorter: (a, b) => {
          return a.domain - b.domain;
        },
        render: (domainId) => {
          const domain = Domain.getFromId(domainId);
          return (
            <div align="center">
              <img
                src={Domain.getFromId(domainId).icons}
                alt={`Icone pour ${domain.name}`}
                style={{ height: 35 }}
              />
              <br />
              <span style={{ fontWeight: 'bold', color: domain.color }}>
                {domain.name}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Contenu',
        dataIndex: 'content',
        render: (content) => content.substr(0, 80) + '...',
      },
      {
        title: 'Visibilité',
        dataIndex: 'visible',
        render: (visible) =>
          visible ? (
            <EyeTwoTone style={{ fontSize: 24 }} />
          ) : (
            <EyeInvisibleTwoTone style={{ fontSize: 24 }} />
          ),
      },
      {
        title: 'Auteur',
        dataIndex: 'author',
        sorter: (a, b) => {
          const table = [a.author, b.author];
          const sorted = table.sort();
          return sorted[0] === table[0];
        },
        render: (content) => <span>{content}</span>,
      },
      {
        title: 'Date de publication',
        dataIndex: 'datePublished',
        render: (date, row) => (
          <Fragment>
            <span>
              <strong>Publiée le : </strong>
              {date.format('L à HH:mm')}
            </span>
            <br />
            <span>
              <strong>Modifiée le : </strong>
              {row.dateUpdated.format('L à HH:mm')}
            </span>
          </Fragment>
        ),
      },
      {
        title: 'Actions',
        render: (news) => {
          return (
            <Fragment>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  this.setState({
                    currentNews: news,
                    currentAction: 'UPDATE',
                    modalVisible: true,
                  });
                }}
                loading={this.state.isLoading}
                type={'primary'}
                style={{ marginRight: 12 }}
              >
                {' '}
                Modifier
              </Button>
              <Button
                icon={<DeleteOutlined />}
                loading={this.state.isLoading}
                onClick={() => {
                  this.setState({ currentNews: news }, () => this.deleteNews());
                }}
                type={'danger'}
              >
                {' '}
                Supprimer
              </Button>
            </Fragment>
          );
        },
      },
    ],
  };

  getAuthors() {
    const uniqAuthors = _.uniqBy(this.state.news, 'author');
    const authorsFilter = uniqAuthors.map((a) => {
      return { text: a.author, value: a.author };
    });
    this.setState({ authorsFilter });
  }

  componentDidMount() {
    this.props.fetchNews();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      notification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });
      this.props.clearError();
    } else if (nextProps.news !== this.props.news) {
      this.setState(
        { news: _.cloneDeep(nextProps.news), modalVisible: false },
        () => this.getAuthors(),
      );
    } else if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }
  }

  changeInput(type, value) {
    const currentNews = this.state.currentNews;

    switch (type) {
      case 'SEND_PUSH':
        currentNews.sendPush = value;
        break;
      case 'DOMAIN':
        currentNews.domain = value;
        break;
      case 'CONTENT':
        currentNews.content = value;
        break;
      case 'VISIBILITY':
        currentNews.visible = value;
        break;
    }

    this.setState({ currentNews });
  }

  deleteNews() {
    this.props.deleteNews(this.state.currentNews);
  }

  publishContent() {
    if (this.state.currentAction === 'CREATE') {
      this.props.createNews(this.state.currentNews);
    } else {
      this.props.updateNews(this.state.currentNews);
    }
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Actualités</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <h1>Actualités</h1>

          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              this.setState({
                modalVisible: !this.state.modalVisible,
                currentNews: new News(),
                currentAction: 'CREATE',
              })
            }
          >
            Nouvelle actualité
          </Button>

          <Table
            style={{ marginTop: 24 }}
            columns={this.state.tableModel}
            dataSource={this.state.news}
            rowKey={'id'}
          />
        </div>
        {this.getModal()}
      </Content>
    );
  }

  getModal() {
    const domains = [2, 3, 4, 5, 6, 7];

    return (
      <Modal
        visible={this.state.modalVisible}
        onCancel={() =>
          this.setState({
            modalVisible: !this.state.modalVisible,
            currentAction: 'CREATE',
            currentNews: new News(),
          })
        }
        onOk={() => this.publishContent()}
        cancelText={'Annuler'}
        okText={
          this.state.currentAction === 'CREATE' ? 'Publier' : 'Mettre à jour'
        }
        title="Nouvelle actualité"
      >
        <Select
          style={{ width: 180, height: 50 }}
          placeholder={'Domaine du document'}
          value={this.state.currentNews.domain}
          onChange={(value) => this.changeInput('DOMAIN', value)}
        >
          {domains.map((domainId) => {
            const domain = Domain.getFromId(domainId);
            return (
              <Option value={domain.id} key={domain.key}>
                <img style={{ width: 30, height: 30 }} src={domain.icons} />
                {domain.name}
              </Option>
            );
          })}
        </Select>

        <TextArea
          autosize={{ minRows: 4, maxRows: 12 }}
          placeholder={'Contenu de votre actualité'}
          value={this.state.currentNews.content}
          onChange={(e) => this.changeInput('CONTENT', e.target.value)}
        />

        {this.state.currentAction === 'UPDATE' ? (
          <Fragment>
            <br />
            <Switch
              checkedChildren={<EyeOutlined />}
              unCheckedChildren={<EyeInvisibleOutlined />}
              onChange={(value) => this.changeInput('VISIBILITY', value)}
              checked={this.state.currentNews.visible}
            />{' '}
            {this.state.currentNews.visible ? (
              'Afficher'
            ) : (
              <strong>Ne pas afficher</strong>
            )}{' '}
            l'actualité sur l'application
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <Checkbox
              checked={this.state.currentNews.sendPush}
              disabled={this.state.currentAction === 'UPDATE'}
              onChange={(e) => this.changeInput('SEND_PUSH', e.target.checked)}
            >
              {' '}
              Envoyer une notification push{' '}
            </Checkbox>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default NewsPage;
