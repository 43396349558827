import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Breadcrumb,
  Button,
  Alert,
  Modal,
  Input,
  Radio,
  Table,
  Icon,
  notification,
} from 'antd';
import EPI from '../../../core/EPI';
import * as _ from 'lodash';
import moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MailOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';

const { Content } = Layout;

export default class EquipmentsPage extends Component {
  static propTypes = {
    fetchEpis: PropTypes.func.isRequired,
    epis: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    createEpi: PropTypes.func.isRequired,
    deleteEpi: PropTypes.func.isRequired,
    updateEpi: PropTypes.func.isRequired,

    fetchEquipements: PropTypes.func.isRequired,
    equipments: PropTypes.array.isRequired,

    error: PropTypes.string,
    clearError: PropTypes.func.isRequired,
  };

  state = {
    epiModalIsVisible: false,
    epis: [],
    equipment: [],
    isLoading: false,
    currentEpi: new EPI(),
    currentAction: '',
  };

  epiColumns = [
    {
      title: 'Nom',
      dataIndex: 'designation',
    },
    {
      title: 'Type',
      dataIndex: 'verifiable',
      sorter: (a, b) => {
        return a.verifiable !== b.verifiable;
      },
      render: (v) =>
        v ? (
          <Fragment>
            <EyeOutlined /> Vérifiable{' '}
          </Fragment>
        ) : (
          <Fragment>
            <DeleteOutlined /> Périssable
          </Fragment>
        ),
    },
    {
      title: 'Ajouté le',
      dataIndex: 'createdAt',
      render: (v) => {
        const date = moment.isMoment(v)
          ? v.format('LL')
          : moment(v).format('LL');

        return (
          <Fragment>
            {' '}
            Créé le <strong>{date}</strong>
          </Fragment>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (epi) => {
        return (
          <Fragment>
            <Button
              type={'primary'}
              icon={<EditOutlined />}
              onClick={() => {
                this.setState({
                  currentEpi: epi,
                  currentAction: 'UPDATE',
                  epiModalIsVisible: true,
                });
              }}
              style={{ marginRight: 12 }}
            >
              {' '}
              Mettre à jour{' '}
            </Button>

            <Button
              type={'danger'}
              icon={<DeleteOutlined />}
              onClick={() => {
                this.setState(
                  { currentEpi: epi, currentAction: 'DELETE' },
                  () => this.deleteEpi(),
                );
              }}
            >
              {' '}
              Supprimer{' '}
            </Button>
          </Fragment>
        );
      },
    },
  ];

  equipmentColumns = [
    {
      title: 'Type',
      dataIndex: 'epi',
      sorter: (a, b, order) => {
        if (order === 'ascend') {
          return a.epi.designation.localeCompare(b.epi.designation) > 0;
        } else {
          return a.epi.designation.localeCompare(b.epi.designation) < 0;
        }
      },
      render: (v) => <Fragment>{v.designation}</Fragment>,
    },
    {
      title: 'Propriétaire',
      dataIndex: 'user',
      sorter: (a, b, order) => {
        if (order === 'ascend') {
          return a.user.email.localeCompare(b.user.email) > 0;
        } else {
          return a.user.email.localeCompare(b.user.email) < 0;
        }
      },
      render: (u) => (
        <Fragment>
          {' '}
          <a href={`mailto:${u.email}`}>
            <MailOutlined /> {u.firstname} {u.lastname}{' '}
          </a>{' '}
          <br />
          Agence : {u.agency.name}{' '}
        </Fragment>
      ),
    },
    {
      title: 'Expiration',
      dataIndex: 'expiry',
      sorter: (a, b) => {
        const expiryA = moment(a.expiry, 'YYYY-MM-DD');
        const expiryB = moment(b.expiry, 'YYYY-MM-DD');
        return expiryA.isBefore(expiryB);
      },
      render: (d) => {
        const expiry = moment(d, 'YYYY-MM-DD');
        const duration = moment.duration(moment().diff(expiry));
        const isExpired = moment().isAfter(expiry);
        const monthsBeforeExpiry = moment
          .duration(expiry.diff(moment()))
          .asMonths();

        if (isExpired) {
          return (
            <div style={{ color: '#af0011', fontWeight: 'bold' }}>
              <WarningOutlined /> A expiré le {expiry.format('L')} (Il y a{' '}
              {duration.humanize()})
            </div>
          );
        } else if (monthsBeforeExpiry < 4) {
          return (
            <div style={{ color: '#ffa400', fontWeight: 'bold' }}>
              <ExclamationCircleOutlined /> Expirera bientôt le{' '}
              {expiry.format('L')} (Dans {duration.humanize()})
            </div>
          );
        } else {
          return (
            <div>
              Expirera le {expiry.format('L')} (Dans {duration.humanize()})
            </div>
          );
        }
      },
    },
  ];

  componentDidMount() {
    this.props.fetchEpis();
    this.props.fetchEquipements();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      notification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });
      this.props.clearError();
    } else if (this.props.isLoading !== nextProps.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    } else if (this.props.epis !== nextProps.epis) {
      let description = null;

      switch (this.state.currentAction) {
        case 'CREATE':
          description = 'EPI ajouté avec succès !';
          break;
        case 'DELETE':
          description = 'EPI supprimé avec succès !';
          break;
        case 'UPDATE':
          description = 'EPI mis à jour avec succès !';
      }

      if (description)
        notification.success({ message: "All's good ! 👌🏻", description });

      this.setState({
        epis: _.cloneDeep(nextProps.epis),
        epiModalIsVisible: false,
        currentAction: '',
      });
    } else if (this.props.equipments !== nextProps.equipments) {
      this.setState({ equipments: _.cloneDeep(nextProps.equipments) });
    }
  }

  createOrUpdate() {
    switch (this.state.currentAction) {
      case 'CREATE':
        this.props.createEpi(this.state.currentEpi);
        break;
      case 'UPDATE':
        this.props.updateEpi(this.state.currentEpi);
        break;
    }
  }

  deleteEpi() {
    this.props.deleteEpi(this.state.currentEpi);
  }

  changeInput(type, value) {
    const currentEpi = this.state.currentEpi;
    switch (type) {
      case 'DESIGNATION':
        currentEpi.designation = value;
        break;
      case 'TYPE':
        currentEpi.verifiable = value;
        break;
    }
    this.setState({ currentEpi });
  }

  epiModal() {
    return (
      <Modal
        title={'Ajouter un EPI'}
        okText={
          this.state.currentAction === 'CREATE'
            ? `Ajouter l'EPI`
            : `Modifier l'EPI`
        }
        cancelText={'Annuler'}
        visible={this.state.epiModalIsVisible}
        onCancel={() =>
          this.setState({ epiModalIsVisible: !this.state.epiModalIsVisible })
        }
        onOk={() => this.createOrUpdate()}
        confirmLoading={this.state.isLoading}
      >
        <Input
          placeholder={"Nom de l'EPI"}
          style={{ marginBottom: 24 }}
          value={this.state.currentEpi.designation}
          onChange={(e) => this.changeInput('DESIGNATION', e.target.value)}
        />
        <Radio.Group
          value={this.state.currentEpi.verifiable}
          onChange={(e) => this.changeInput('TYPE', e.target.value)}
        >
          <strong>Vérification</strong>
          <br />
          <Radio value={true} defaultChecked>
            Verifiable
          </Radio>
          <br />
          <Radio value={false}> Périsable</Radio>
          <br />
        </Radio.Group>
      </Modal>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>EPIs & Equipements</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <h1>EPIs</h1>
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              this.setState({
                epiModalIsVisible: !this.state.epiModalIsVisible,
                currentAction: 'CREATE',
                currentEpi: new EPI(),
              })
            }
            style={{ marginBottom: 24 }}
          >
            Nouvel EPI
          </Button>
          <Table
            columns={this.epiColumns}
            dataSource={this.state.epis}
            rowKey={'id'}
          />
        </div>
        <div
          style={{
            padding: 24,
            marginTop: 24,
            background: '#fff',
            minHeight: 360,
          }}
        >
          <h1>Equipements</h1>
          <Alert
            message="Information"
            type="info"
            showIcon
            description="L'ajout d'un équipement ne peut se faire que via l'application QHSE"
            style={{ marginBottom: 24 }}
          />

          <Table
            columns={this.equipmentColumns}
            dataSource={this.state.equipments}
            rowKey={'id'}
          />
        </div>
        {this.epiModal()}
      </Content>
    );
  }
}
