// @flow

import React, { Component, Fragment } from 'react';
// import PropTypes from "prop-types";
import {
  Layout,
  Breadcrumb,
  Table,
  Alert,
  Icon,
  Button,
  Drawer,
  Divider,
  Row,
  Col,
  Popconfirm,
  notification,
} from 'antd';
import {
  EyeOutlined,
  UserOutlined,
  StarOutlined,
  ShopOutlined,
  DislikeOutlined,
  DeleteOutlined,
  GlobalOutlined,
  MailOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  IdcardOutlined,
  StopOutlined,
  MobileOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import User from '../../../core/User';
import './users.sass';
import moment from 'moment';
import Domain from '../../../core/Domain';

const { Content } = Layout;

export default class UsersPage extends Component {
  static propTypes = {};

  state = {
    users: [],
    currentUser: new User(),
    drawerVisible: false,
  };

  tableModel = [
    {
      title: 'Nom',
      key: 'id',
      sorter: (a, b) => a.lastname.length - b.lastname.length,
      render: (u) => <>{u.lastname.toUpperCase()}</>,
    },
    {
      title: 'Prénom',
      key: 'id',
      sorter: (a, b) => a.firstname.length - b.firstname.length,
      render: (u) => <>{u.firstname}</>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (mail) => (
        <>
          <MailOutlined /> {mail}
        </>
      ),
    },
    {
      title: 'Agence',
      dataIndex: 'agency',
      sorter: (userA, userB, order) => {
        if (order === 'ascend')
          return userA.agency.name.localeCompare(userB.agency.name) > 0;
        return userA.agency.name.localeCompare(userB.agency.name) < 1;
      },
      render: (agency) => (
        <>
          <GlobalOutlined /> {agency.name} ({agency.region.name})
        </>
      ),
    },
    {
      title: 'Rôles',
      key: 'role',
      filters: [
        { text: 'Référent', value: 'isReferent' },
        { text: 'Directeur Régional', value: 'isDirector' },
        { text: 'Expert', value: 'isExpert' },
        { text: 'Super-Expert', value: 'isSuperExpert' },
      ],
      onFilter: (value, record) => record[value],
      render: (user) => (
        <>
          {user.isReferent ? <div className="user__role">Référent</div> : ''}
          {user.isDirector ? (
            <div className="user__role">
              <img
                src={
                  require('../../../assets/domaines/director@3x.png').default
                }
              />{' '}
              Directeur Régional
            </div>
          ) : (
            ''
          )}
          {user.isExpert ? (
            <div className="user__role">
              <img
                src={require('../../../assets/domaines/expert@3x.png').default}
              />{' '}
              Expert National
            </div>
          ) : (
            ''
          )}
          {user.isSuperExpert ? (
            <div className="user__role">
              <img
                src={
                  require('../../../assets/domaines/super-expert@3x.png')
                    .default
                }
              />{' '}
              Super Expert
            </div>
          ) : (
            ''
          )}
          {user.isAdmin ? (
            <div className="user__role">
              <img
                src={require('../../../assets/domaines/admin.svg').default}
              />{' '}
              Admin
            </div>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Action',
      render: (u) => (
        <>
          <Button
            type="default"
            icon={<EyeOutlined />}
            onClick={() =>
              this.setState({
                drawerVisible: !this.state.drawerVisible,
                currentUser: u,
              })
            }
          >
            {' '}
            Voir le profil détaillé
          </Button>
        </>
      ),
    },
  ];

  async componentDidMount() {
    await this.reloadUsers();
  }

  async reloadUsers() {
    const users = await User.fetch();
    this.setState({ users, drawerVisible: false });
  }

  async promoteUser(user, asDirector = false, asExpert = false) {
    await user.promote(asDirector, asExpert);
    await this.reloadUsers();
    notification.success({
      message: 'Promotion effectuée !',
      description: `Modificiation de la fonction de ${user.firstname} ${user.lastname} prise en compte`,
    });
  }

  async deleteUser(user) {
    await user.delete();
    await this.reloadUsers();
    notification.success({
      message: 'Utilisateur supprimé avec succès !',
      description: `${user.firstname} ${user.lastname} a été supprimé️`,
    });
  }

  getCheckIcon(type) {
    return type ? (
      <span>
        <CheckCircleTwoTone twoToneColor="#af0011" /> <strong>Oui</strong>
      </span>
    ) : (
      <span>
        <CloseCircleTwoTone twoToneColor="#af0011" />
        <strong>Non</strong>
      </span>
    );
  }

  userDrawer() {
    const { currentUser, drawerVisible } = this.state;

    return (
      <Drawer
        title={
          <h1>
            <UserOutlined
              type="user"
              style={{ color: '#0067ee', marginRight: 24 }}
            />
            {currentUser.firstname} {currentUser.lastname.toUpperCase()}
          </h1>
        }
        placement="right"
        width={800}
        closable
        onClose={() => this.setState({ drawerVisible: !drawerVisible })}
        visible={drawerVisible}
      >
        <h2>
          <IdcardOutlined style={{ color: '#0067ee', marginRight: 12 }} />
          Informations générales
        </h2>
        <Row>
          <Col span={12}>
            <ul className="profile">
              <li>
                {currentUser.firstname} {currentUser.lastname}
              </li>
              <li>{currentUser.email}</li>
              <li>{currentUser.phoneNumber}</li>
            </ul>
          </Col>
          <Col span={12}>
            <ul className="profile">
              <li>
                Compte créé le
                {moment(currentUser.user_created_at).format('LLL')}
              </li>
              <li>
                Dernière mise à jour le
                {moment(currentUser.user_updated_at).format('LLL')}
              </li>
            </ul>
          </Col>
        </Row>

        <Divider />
        <h2>
          <ShopOutlined style={{ color: '#0067ee', marginRight: 12 }} />
          Agence & Reponsabilités
        </h2>

        <Row>
          <Col span={12}>
            <h3>Rôle & Attributions</h3>
            <ul className="profile">
              <li>
                Fonction :<strong>{currentUser.position}</strong>
              </li>
              <li>
                Référent :
                <strong>{this.getCheckIcon(currentUser.isReferent)}</strong>
              </li>
              <li>
                Directeur régional :
                <strong>{this.getCheckIcon(currentUser.isDirector)}</strong>
              </li>
              <li>
                Expert QHSE :
                <strong>{this.getCheckIcon(currentUser.isExpert)}</strong>
              </li>
              <li>
                Super-expert :
                <strong>{this.getCheckIcon(currentUser.isSuperExpert)}</strong>
              </li>
            </ul>
          </Col>
          <Col span={12}>
            <h3>Lieu de travail</h3>
            <ul className="profile">
              <li>
                Agence :
                <strong>
                  {currentUser.agency.name} ({currentUser.agency.code})
                </strong>
              </li>
              <li>
                Région :
                <strong>
                  {currentUser.agency.region.name} (
                  {currentUser.agency.region.code})
                </strong>
              </li>
            </ul>
            <br />
            <h3>
              Domaine(s)
              {currentUser.isReferent
                ? 'de référence'
                : currentUser.isExpert || currentUser.isExpert
                ? "d'expertise"
                : currentUser.isDirector
                ? 'non délégués'
                : ''}
            </h3>
            <ul className="profile">
              {currentUser.domains.length === 0 ? (
                <>
                  <StopOutlined />
                  Aucun Domaine
                </>
              ) : (
                <ul>
                  {currentUser.domains.map((domain) => {
                    const d = Domain.getFromId(domain);
                    return (
                      <li key={d.key}>
                        <img src={d.icons} width={25} height={25} />
                        {d.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </ul>
          </Col>
        </Row>

        <Divider />

        <h2>
          <MobileOutlined style={{ color: '#0067ee', marginRight: 12 }} />
          Application
        </h2>

        <Alert
          showIcon
          message="Communiquer les information ci-dessous à DBCall en cas de problème avec cet utilisateur"
        />

        <ul className="profile">
          <li>
            Application ID :
            <strong style={{ fontFamily: 'monospace' }}>
              {currentUser.appId}
            </strong>
          </li>
          <li>
            Device UUID :
            <strong style={{ fontFamily: 'monospace' }}>
              {currentUser.uuid}
            </strong>
          </li>
          <li>
            Token notification :
            <strong style={{ fontFamily: 'monospace' }}>
              {currentUser.pushToken}
            </strong>
          </li>
        </ul>

        <Divider />
        <h2>
          <StarOutlined style={{ color: '#0067ee', marginRight: 12 }} />
          Privilèges
        </h2>
        <Button.Group>
          <Button
            htmlType="button"
            icon={<StarOutlined />}
            disabled={currentUser.isDirector || currentUser.isAdmin}
            onClick={() => this.promoteUser(currentUser, true, false)}
          >
            {' '}
            Promotion Directeur Régional{' '}
          </Button>

          <Button
            htmlType="button"
            icon={<ShopOutlined />}
            disabled={!currentUser.isReferent || currentUser.isAdmin}
            onClick={() => this.promoteUser(currentUser, false, true)}
          >
            {' '}
            Promotion Expert{' '}
          </Button>
        </Button.Group>
        <Divider />
        <h2 className="DangerZone">
          <WarningOutlined style={{ marginRight: 12 }} />
          Zone de danger
        </h2>
        <Button.Group>
          <Button
            htmlType="button"
            icon={<DislikeOutlined />}
            type="danger"
            disabled={currentUser.isAdmin}
            onClick={() => this.promoteUser(currentUser, false, false)}
          >
            {' '}
            Révoquer Privilèges{' '}
          </Button>

          <Popconfirm
            title={`Êtes vous certain de vouloir supprimer de compte de ${currentUser.firstname} ? Cette action est définitive !`}
            okText="Oui"
            cancelText="En fait, non !"
            onConfirm={() => this.deleteUser(currentUser)}
          >
            <Button
              htmlType="button"
              icon={<DeleteOutlined />}
              type="danger"
              disabled={currentUser.isAdmin}
            >
              {' '}
              Supprimer cet utilisateur
            </Button>
          </Popconfirm>
        </Button.Group>
      </Drawer>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Utilisateurs</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <h1>Utilisateurs</h1>

          <Alert
            message="Information"
            type="info"
            showIcon
            description="L'ajout d'un utilisateur ne peut se faire que via l'application mobile QHSE"
          />

          <Table
            style={{ marginTop: 24 }}
            columns={this.tableModel}
            dataSource={this.state.users}
            rowKey="id"
          />
        </div>
        {this.userDrawer()}
      </Content>
    );
  }
}
