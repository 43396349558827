import AgencyApi from "./API/Agency.api";
import Region from "./Region";

export default class Agency {

  constructor() {

    this.id = 0;
    this.code = '';
    this.name = '';
    this.region = new Region();
    this.operationArea = [];
    this.enterprise = {};

  }

  async create() {
    try {
      const createdAgency = await AgencyApi.create(this);
      this.enterprise = createdAgency.enterprise;
      this.region = createdAgency.region;
      this.code = createdAgency.code;
      return this;
    } catch(error) {
      throw error;
    }
  }

  async update() {
    try {
      const agencyRaw = await AgencyApi.update(this);
      return Object.assign(new Agency(), agencyRaw, {code: parseInt(agencyRaw.code)});
    } catch(error) {
      throw error;
    }
  }

  async delete() {
    try {
      await AgencyApi.delete(this);
    } catch(error) {
      throw error;
    }
  }

  static async getAll() {
    try {
      const agencies = await AgencyApi.getAll();
      return agencies.map(a => Object.assign(new Agency(), a));
    } catch(error) {
      throw error;
    }

  }

}
