import {Instance} from './base'

export default class RegionApi {

  static async getAll() {

      try {
        const response = await Instance(true).request.get('/v2/regions');
        return response.data.data;
      } catch(error) {
        throw new Error(error.message);
      }


  }
  static async checkCode(code) {

    try {
      const response = await Instance(true).request({
        url: '/v2/regions/code/' + code,
        method: 'head',
        validateStatus: (status) => {return status === 404}
      });
      return response.status;
    } catch(error) {
      return  error.response.status;
    }




  }
  static async delete(region) {
    try {
      await Instance(true).request.delete('/v2/regions/' + region.id);
      return true;
    } catch(error) {
      throw error;
    }

  }

  static async create(region) {
    try {
      const response = await Instance(true).request.post('/v2/regions', {
        name: region.name,
        code: region.code,
      });
      return response.data.data;
    } catch(error) {
      throw new Error(error.message);
    }
  }
  static async update(region) {
    try {
      const response = await Instance(true).request.put('/v2/regions/' + region.id, {
        name: region.name,
        code: region.code,
      });
      return response.data.data;
    } catch(error) {
      throw new Error(error.message);
    }

  }



}
