import React, { Component, Fragment } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Layout,
  Breadcrumb,
  Button,
  Modal,
  Input,
  Select,
  Table,
  Alert,
  Tag,
  notification,
} from 'antd';
import Agency from '../../../core/Agency';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;

class AgenciesPage extends Component {
  static propTypes = {
    regions: PropTypes.array.isRequired,
    agencies: PropTypes.array.isRequired,
    errors: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,

    getRegions: PropTypes.func.isRequired,
    getAgencies: PropTypes.func.isRequired,
    createAgency: PropTypes.func.isRequired,
    updateAgency: PropTypes.func.isRequired,
    deleteAgency: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,

    error: PropTypes.string,
  };

  agencyModel = [
    {
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Code unique',
      dataIndex: 'code',
    },
    {
      title: 'Zone de travail',
      dataIndex: 'operationArea',
      render: (v) =>
        v.map((d) => (
          <Tag key={d} color={'blue'}>
            Dpt. {d}
          </Tag>
        )),
    },
    {
      title: "Région d'attache",
      dataIndex: 'region',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (v) => v.name,
    },
    {
      title: 'Action',
      key: 'x',
      render: (agency) => (
        <React.Fragment>
          <Button
            type={'primary'}
            icon={<EditOutlined />}
            style={{ marginRight: 12 }}
            onClick={() => {
              this.setState({
                currentAgency: agency,
                currentAgencyAction: 'UPDATE',
                addAgencyModalOpened: true,
              });
            }}
          >
            {' '}
            Editer{' '}
          </Button>
          <Button
            type={'danger'}
            icon={<DeleteOutlined />}
            onClick={() => {
              this.setState(
                {
                  currentAgency: agency,
                  currentAgencyAction: 'DELETE',
                },
                () => this.deleteAgency(),
              );
            }}
          >
            {' '}
            Supprimer{' '}
          </Button>
        </React.Fragment>
      ),
    },
  ];

  state = {
    addAgencyModalOpened: false,
    agencies: [],
    regions: [],
    currentAgency: new Agency(),
    currentAgencyAction: '',

    departmentsString: '',
    isLoading: false,
    checkCodeResponse: 0,
  };

  componentDidMount() {
    this.props.getRegions();
    this.props.getAgencies();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      notification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });
      this.props.clearError();
    } else if (this.props.agencies !== nextProps.agencies) {
      let description = null;

      switch (this.state.currentAgencyAction) {
        case 'CREATE':
          description = `Agence créée avec succès`;
          break;
        case 'UPDATE':
          description = `Agence mis à jour avec succès`;
          break;
        case 'DELETE':
          description = `Agence supprimée avec succès`;
          break;
        default:
          break;
      }

      if (description)
        notification.success({
          message: 'Action effectuée !  👌🏻',
          description,
        });
      this.setState({
        agencies: _.cloneDeep(nextProps.agencies),
        addAgencyModalOpened: false,
        currentAgencyAction: '',
      });
    } else if (this.props.regions !== nextProps.regions) {
      this.setState({ regions: nextProps.regions });
    } else if (this.props.isLoading !== nextProps.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }
  }

  /* Agency */

  createOrUpdateAgency() {
    switch (this.state.currentAgencyAction) {
      case 'CREATE':
        this.props.createAgency(this.state.currentAgency);
        break;
      case 'UPDATE':
        this.props.updateAgency(this.state.currentAgency);
        break;
    }
  }

  deleteAgency() {
    this.props.deleteAgency(this.state.currentAgency);
  }

  changeAgencyInput(type, value) {
    const currentAgency = this.state.currentAgency;
    let departmentsString = '';
    switch (type) {
      case 'REGION':
        currentAgency.region.code = value;
        break;
      case 'NAME':
        currentAgency.name = value;
        break;
      case 'DEPARTMENTS':
        if (value.charAt(value.length - 1) === ';') {
          currentAgency.operationArea.push(value.slice(0, -1));
          departmentsString = '';
        } else {
          departmentsString = value;
        }

        break;
    }

    this.setState({
      departmentsString,
      currentAgency,
    });
  }

  removeDepartment(value) {
    const currentAgency = this.state.currentAgency;
    _.pull(currentAgency.operationArea, value);
    this.setState({ currentAgency });
  }

  agencyModal() {
    return (
      <Modal
        centered
        title={'Ajouter une agence'}
        okText={
          this.state.currentAgencyAction === 'CREATE'
            ? "Ajouter l'agence"
            : "Modifier l'agence"
        }
        cancelText={'Annuler'}
        visible={this.state.addAgencyModalOpened}
        onOk={() => this.createOrUpdateAgency()}
        confirmLoading={this.state.isLoading}
        onCancel={() =>
          this.setState({
            addAgencyModalOpened: !this.state.addAgencyModalOpened,
          })
        }
      >
        <Select
          style={{ minWidth: 200, marginBottom: 12 }}
          placeholder={`Région d'appartenance`}
          value={
            this.state.currentAgency.region.code
              ? this.state.currentAgency.region.code
              : undefined
          }
          onChange={(v) => this.changeAgencyInput('REGION', v)}
        >
          {this.state.regions.map((r) => (
            <Select.Option value={r.code} key={r.code}>
              {r.name}
            </Select.Option>
          ))}
        </Select>
        <Input
          placeholder={"Nom de l'agence"}
          maxLength={30}
          style={{ marginBottom: 12 }}
          value={this.state.currentAgency.name}
          onChange={(e) => this.changeAgencyInput('NAME', e.target.value)}
        />
        <Input
          placeholder={'Départements (séparés par ";")'}
          maxLength={30}
          style={{ marginBottom: 12 }}
          value={this.state.departmentsString}
          onChange={(e) =>
            this.changeAgencyInput('DEPARTMENTS', e.target.value)
          }
        />
        <br />
        {this.state.currentAgency.operationArea.map((d) => (
          <Tag
            key={`${d}_${Math.random().toString()}`}
            color={'blue'}
            closable
            onClose={() => this.removeDepartment(d)}
          >
            Département {d}
          </Tag>
        ))}
      </Modal>
    );
  }

  agencesJSX() {
    return (
      <Fragment>
        <h1>Agences</h1>

        <Button
          style={{ marginBottom: 24 }}
          icon={<PlusOutlined />}
          onClick={() => {
            const agency = new Agency();
            agency.region.code = null;

            this.setState({
              addAgencyModalOpened: !this.state.addAgencyModalOpened,
              currentAgency: agency,
              currentAgencyAction: 'CREATE',
            });
          }}
        >
          Nouvelle agence
        </Button>
        <Alert
          message={
            <Fragment>
              La suppression d'une agence entraîne{' '}
              <strong>la suppression des utilisateurs attachés</strong>.
            </Fragment>
          }
          type="warning"
          showIcon
        />
        <Table
          columns={this.agencyModel}
          rowKey={'code'}
          dataSource={this.state.agencies}
        />
      </Fragment>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Agences & Regions</Breadcrumb.Item>
          <Breadcrumb.Item>Agences </Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          {this.agencesJSX()}
        </div>
        {this.agencyModal()}
      </Content>
    );
  }
}

export default AgenciesPage;
