import {
  cloneDeep,
  remove,
} from 'lodash';
import { AGENCIES, CLEAR_ERROR } from '../constants/actionsTypes';

export default (state = {}, action) => {
  const data = cloneDeep(state.data);

  switch (action.type) {
    case AGENCIES.FETCH:
      return { ...state, isLoading: action.isLoading };
    case AGENCIES.LIST.SUCCESS:
      return { ...state, data: action.agencies, error: null };

    case AGENCIES.CREATE.SUCCESS:
      data.push(action.agency);
      return { ...state, data, error: null };

    case AGENCIES.UPDATE.SUCCESS:
      remove(data, { code: action.agency.code });
      data.push(action.agency);
      return { ...state, error: null, data };

    case AGENCIES.DELETE.SUCCESS:
      remove(data, { code: action.agencyId });
      return { ...state, error: null, data };

    case AGENCIES.LIST.FAILED:
    case AGENCIES.CREATE.FAILED:
    case AGENCIES.UPDATE.FAILED:
    case AGENCIES.DELETE.FAILED:
      return { ...state, error: action.error };

    case CLEAR_ERROR:
      return { ...state, error: null };

    default:
      return state;
  }
};
