import {Instance} from "./base";

export default class EPIAPI {

  static async getEquipments() {

    try {
      const response = await Instance(true).request.get('/v2/equipments');
      return response.data.data;
    } catch(error) {
      throw new Error(error.response.data.error);
    }


  }

  static async read() {

    try {
      const response = await Instance(true).request.get('/v2/epis');
      return response.data.data;
    } catch(error) {
      throw new Error(error.response.data.error);
    }

  }
  static async create(epi) {

    try {
      const response = await Instance(true).request.post('/v2/epis', {
        epi_designation: epi.designation,
        epi_verifiable: epi.verifiable,
      });
      return response.data.data;
    } catch(error) {
      throw new Error(error.response.data.error);
    }


  }
  static async update(epi) {

    try {
      const response = await Instance(true).request.put('/v2/epis/' + epi.id, {
        epi_designation: epi.designation,
        epi_verifiable: epi.verifiable,
      });
      return response.data.data;
    } catch(error) {
      throw new Error(error.response.data.error);
    }

  }

  static async delete(epi) {
    await Instance(true).request.delete('/v2/epis/' + epi.id);
    return true;
  }

}
