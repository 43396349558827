import React, { Component } from "react";
import { connect } from "react-redux";
import AgenciesPage from "../components/Views/Agencies";
import { listAgencies, createAgency, updateAgency, deleteAgency } from "../actions/agencies.actions";
import { listRegions } from "../actions/regions.actions";
import { clearErrors } from '../actions/error.action';


class AgenciesContainer extends Component {
  render() {
    return (
      <AgenciesPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    regions: store.regions.data,
    agencies: store.agencies.data,
    error: store.agencies.error,
    isLoading: store.agencies.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    getRegions: () => dispatch(listRegions()),

    getAgencies: () => dispatch(listAgencies()),
    createAgency: (agency) => dispatch(createAgency(agency)),
    updateAgency: (agency) => dispatch(updateAgency(agency)),
    deleteAgency: (agency) => dispatch(deleteAgency(agency)),
    clearError: () => dispatch(clearErrors())

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesContainer);
