// Set up your root reducer here...
import { combineReducers } from 'redux';
import user from './user.reducer';
import indicators from './indicators.reducer';
import documents from './documents.reducer';
import regions from './regions.reducer';
import agencies from './agencies.reducer';
import news from './news.reducer';
import epis from './epis.reducer';
import equipments from './equipements.reducer';
import {connectRouter} from "connected-react-router";

export default (history) => combineReducers({
    router: connectRouter(history),
    user,
    indicators,
    regions,
    agencies,
    news,
    documents,
    epis,
    equipments
  });
