import {Instance} from './base'

export default class IndicatorAPI {

  static async getForEnterprise() {

    try {
      const indicatorsResponse = await Instance(true).request.get('/v2/indicators/enterprise');
      return indicatorsResponse.data.data;

    } catch(error) {
      throw new Error(error.response.data.error);
    }

  }

  static async create(indicator) {

    try {
      const indicatorResponse = await Instance(true).request.post('/v2/indicators', {
        indi_name: indicator.name,
        indi_key: indicator.key,
        indi_last_occurrence: indicator.lastOccurrence.format(),
        indi_region: indicator.regionCode
      });
      return indicatorResponse.data.data

    } catch(error) {

      throw new Error(error.response.data.error);
    }

  }

  static async update(indicator) {

    try {
      const indicatorResponse = await Instance(true).request.put(`/v2/indicators/${indicator.id}`, {
        indi_name: indicator.name,
        indi_key: indicator.key,
        indi_last_occurrence: indicator.lastOccurrence.format(),
        indi_region: indicator.regionCode
      });

      return indicatorResponse.data.data

    } catch(error) {
      throw new Error(error.response.data.error);
    }

  }

  static async delete(indicator) {
    try {
      const indicatorsResponse = await Instance(true).request.delete('/v2/indicators/' + indicator.id);
      return indicatorsResponse.status;

    } catch(error) {
      throw new Error(error.response.data.error);
    }
  }

}
