import {Instance} from './base'

export default class AgencyApi {

  static async getAll() {

      try {
        const response = await Instance(true).request.get('/v2/agencies');
        return response.data.data;
      } catch(error) {
        throw new Error(error.message);
      }


  }
  static async getOne(id) {
    return id;
  }

  static async create(agency) {

    try {

      const response = await Instance(true).request.post('/v2/agencies', {
        name: agency.name,
        region_id: agency.region.code,
        operation_area: agency.operationArea
      });

      return response.data.data;

    } catch(error) {

      throw new Error(error.message);

    }
  }

  static async update(agency) {

    try {
      const response = await Instance(true).request.put('/v2/agencies/' + agency.code, {
        name: agency.name,
        region_id: agency.region.code,
        operation_area: agency.operationArea
      });
      return response.data.data;

    } catch(error) {
      throw new Error(error.message);

    }


  }

  static async delete(agency) {
    try {
      await Instance(true).request.delete('/v2/agencies/' + agency.code);
      return true;
    } catch(error) {
      throw error
    }

  }



}
