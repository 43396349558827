import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Icon from '@ant-design/icons';

import AppLogo from '../../../assets/logo_round.png';
import './Application.sass';

const { Sider } = Layout;

//Icons
import {
  Dashboard,
  Indicators,
  News,
  Document,
  Helmet,
  Users,
  Building,
  Notifications,
} from '../../../assets/icons';

class AppMenu extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        <div className="Menu__Header">
          <img src={AppLogo} />{' '}
          <h1 style={{ display: this.state.collapsed ? 'none' : 'block' }}>
            Application QHSE
          </h1>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          onClick={(keyObject) => {
            this.props.history.push(keyObject.key);
          }}
        >
          <Menu.Item
            key="/dashboard"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon component={Dashboard} style={{ width: 20, height: 20 }} />
            <span>Info. générales</span>
          </Menu.Item>
          <Menu.Item
            key="/indicators"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon component={Indicators} style={{ width: 20, height: 20 }} />
            <span>Indicateurs</span>
          </Menu.Item>
          <Menu.Item
            key="/news"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon component={News} style={{ width: 20, height: 20 }} />
            <span>Actualités</span>
          </Menu.Item>
          <Menu.Item
            key="/documents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon component={Document} style={{ width: 20, height: 20 }} />
            <span>Documents</span>
          </Menu.Item>
          <Menu.Item
            key="/equipements"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon component={Helmet} style={{ width: 20, height: 20 }} />
            <span>Equipements</span>
          </Menu.Item>
          <Menu.Item
            key="/users"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon component={Users} style={{ width: 20, height: 20 }} />
            <span>Utilisateurs</span>
          </Menu.Item>
          <Menu.SubMenu
            title={
              <span>
                <Icon component={Building} style={{ width: 20, height: 20 }} />
                <span>Régions & Agences</span>
              </span>
            }
          >
            <Menu.Item
              key="/regions"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span>Régions</span>
            </Menu.Item>
            <Menu.Item
              key="/agencies"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span>Agences</span>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key="/notifications"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon component={Notifications} style={{ width: 20, height: 20 }} />
            <span>Notifications</span>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(AppMenu);
