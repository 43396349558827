import React, { Component } from "react";
import { connect } from "react-redux";
import EquipmentsPage from "../components/Views/Equipments";
import {listEpis, createEpi, deleteEpi, updateEpi} from "../actions/epis.actions";
import {listEquipments} from "../actions/equipments.actions";
import {clearErrors} from "../actions/error.action";


class EquipmentContainer extends Component {
  render() {
    return (
      <EquipmentsPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    epis: store.epis.data,
    episError: store.epis.error,
    error: store.epis.error,
    equipments: store.equipments.data,
    equipmentsError: store.equipments.error,
    isLoading: store.epis.isLoading,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEpis: () => dispatch(listEpis()),
    fetchEquipements: () => dispatch(listEquipments()),
    createEpi: (epi) => dispatch(createEpi(epi)),
    deleteEpi: (epi) => dispatch(deleteEpi(epi)),
    updateEpi: (epi) => dispatch(updateEpi(epi)),
    clearError: () => dispatch(clearErrors()),


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentContainer);
