import NewsAPI from "./API/News.api";
import moment from "moment";

export default class News {

  constructor(id, domain, content, author, datePublished, dateUpdated, visible) {
    this.id = id;
    this.domain = domain;
    this.content = content;
    this.author = author;
    this.datePublished = moment(datePublished);
    this.dateUpdated = moment(dateUpdated);
    this.visible = visible;
    this.sendPush = false;

  }

  static async getAll() {

    try {
      const rawNews = await NewsAPI.readAll();
      return rawNews.map(n => {
        return new News(n.id, n.domain, n.content, n.author, n.created_at, n.updated_at, n.visible)
      });
    } catch(error) {
      throw error;
    }



  }

  async create() {
    try {
      const createdNews = await NewsAPI.create(this);
      this.id = createdNews.id;
      this.author = createdNews.author;
      this.datePublished = moment(createdNews.actu_created_at);
      this.dateUpdated = moment(createdNews.actu_updated_at);
      this.visible = createdNews.status === 'VISIBLE';
      return this;

    } catch(error) {
      throw error;
    }
  }

  async update() {
    try {
      const updatedNews = await NewsAPI.update(this);
      this.visible = updatedNews.status === 'VISIBLE';
      this.datePublished = moment(updatedNews.actu_created_at);
      this.dateUpdated = moment(updatedNews.actu_updated_at);
      return this;
    } catch(error) {
      throw error;
    }
  }

  async delete() {

    try {
      await NewsAPI.delete(this);
      return this;
    } catch(error) {
      throw error;
    }

  }

}
