const USER = {

  LOGIN: {
    FETCH: 'USER.LOGIN.FETCH',
    SUCCESS: 'USER.LOGIN.SUCCESS',
    FAILED: 'USER.LOGIN.FAILED',
  }


};

const INDICATORS = {
  FETCH: 'INDICATORS.FETCH',
  LIST: {
    SUCCESS: 'INDICATORS.LIST.SUCCESS',
    FAILED: 'INDICATORS.LIST.FAILED'
  },

  CREATE: {
    SUCCESS: 'INDICATORS.CREATE.SUCCESS',
    FAILED: 'INDICATORS.CREATE.FAILED'
  },

  UPDATE: {
    SUCCESS: 'INDICATORS.UPDATE.SUCCESS',
    FAILED: 'INDICATORS.UPDATE.FAILED'
  },

  DELETE: {
    SUCCESS: 'INDICATORS.DELETE.SUCCESS',
    FAILED: 'INDICATORS.DELETE.FAILED'
  }

};

const NEWS = {
  FETCH: 'NEWS.FETCH',
  LIST: {
    SUCCESS: 'NEWS.LIST.SUCCESS',
    FAILED: 'NEWS.LIST.FAILED'
  },

  CREATE: {
    SUCCESS: 'NEWS.CREATE.SUCCESS',
    FAILED: 'NEWS.CREATE.FAILED'
  },

  UPDATE: {
    SUCCESS: 'NEWS.UPDATE.SUCCESS',
    FAILED: 'NEWS.UPDATE.FAILED'
  },

  DELETE: {
    SUCCESS: 'NEWS.DELETE.SUCCESS',
    FAILED: 'NEWS.DELETE.FAILED'
  }

};

const DOCUMENTS = {
  FETCH: 'DOCUMENTS.FETCH',
  LIST: {
    SUCCESS: 'DOCUMENTS.LIST.SUCCESS',
    FAILED: 'DOCUMENTS.LIST.FAILED'
  },

  CREATE: {
    SUCCESS: 'DOCUMENTS.CREATE.SUCCESS',
    FAILED: 'DOCUMENTS.CREATE.FAILED'
  },

  UPDATE: {
    SUCCESS: 'DOCUMENTS.UPDATE.SUCCESS',
    FAILED: 'DOCUMENTS.UPDATE.FAILED'
  },

  DELETE: {
    SUCCESS: 'DOCUMENTS.DELETE.SUCCESS',
    FAILED:  'DOCUMENTS.DELETE.FAILED'
  }

};

const USERS = {
  FETCH: 'USERS.FETCH',
  LIST: {
    SUCCESS: 'USERS.LIST.SUCCESS',
    FAILED: 'USERS.LIST.FAILED'
  },
  UPDATE: {
    SUCCESS: 'USERS.UPDATE.SUCCESS',
    FAILED: 'USERS.UPDATE.FAILED'
  }
};

const EPI = {
  FETCH: 'EPI.FETCH',

  LIST: {
    SUCCESS: 'EPI.LIST.SUCCESS',
    FAILED: 'EPI.LIST.FAILED'
  },

  CREATE: {
    SUCCESS: 'EPI.CREATE.SUCCESS',
    FAILED: 'EPI.CREATE.FAILED'
  },

  UPDATE: {
    SUCCESS: 'EPI.UPDATE.SUCCESS',
    FAILED: 'EPI.UPDATE.FAILED'
  },

  DELETE: {
    SUCCESS: 'EPI.DELETE.SUCCESS',
    FAILED:  'EPI.DELETE.FAILED'
  }

};

const EQUIPMENT = {
  FETCH: 'EQUIPMENT.FETCH',

  LIST: {
    SUCCESS: 'EQUIPMENT.LIST.SUCCESS',
    FAILED: 'EQUIPMENT.LIST.FAILED'
  },

  CREATE: {
    SUCCESS: 'EQUIPMENT.CREATE.SUCCESS',
    FAILED: 'EQUIPMENT.CREATE.FAILED'
  },

  UPDATE: {
    SUCCESS: 'EQUIPMENT.UPDATE.SUCCESS',
    FAILED: 'EQUIPMENT.UPDATE.FAILED'
  },

  DELETE: {
    SUCCESS: 'EQUIPMENT.DELETE.SUCCESS',
    FAILED:  'EQUIPMENT.DELETE.FAILED'
  }

};

const REGIONS = {
  FETCH: 'REGIONS.FETCH',

  LIST: {
    SUCCESS: 'REGIONS.LIST.SUCCESS',
    FAILED: 'REGIONS.LIST.FAILED'
  },

  CREATE: {
    SUCCESS: 'REGIONS.CREATE.SUCCESS',
    FAILED: 'REGIONS.CREATE.FAILED'
  },

  UPDATE: {
    SUCCESS: 'REGIONS.UPDATE.SUCCESS',
    FAILED: 'REGIONS.UPDATE.FAILED'
  },

  DELETE: {
    SUCCESS: 'REGIONS.DELETE.SUCCESS',
    FAILED:  'REGIONS.DELETE.FAILED'
  }

};

const AGENCIES = {
  FETCH: 'AGENCIES.FETCH',

  LIST: {
    SUCCESS: 'AGENCIES.LIST.SUCCESS',
    FAILED: 'AGENCIES.LIST.FAILED'
  },

  CREATE: {
    SUCCESS: 'AGENCIES.CREATE.SUCCESS',
    FAILED: 'AGENCIES.CREATE.FAILED'
  },

  UPDATE: {
    SUCCESS: 'AGENCIES.UPDATE.SUCCESS',
    FAILED: 'AGENCIES.UPDATE.FAILED'
  },

  DELETE: {
    SUCCESS: 'AGENCIES.DELETE.SUCCESS',
    FAILED:  'AGENCIES.DELETE.FAILED'
  }

};

const CLEAR_ERROR = 'CLEAR_ERROR';


export {USER, INDICATORS, NEWS, DOCUMENTS, USERS, EPI, EQUIPMENT, REGIONS, AGENCIES, CLEAR_ERROR}
