import React, { Component } from "react";
import { connect } from "react-redux";
import NewsPage from "../components/Views/News";
import { createNews, deleteNews, fetchNews, updateNews } from "../actions/news.actions";
import {clearErrors} from "../actions/error.action";


class NewsContainer extends Component {
  render() {
    return (
      <NewsPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.news.isLoading,
    error: store.news.error,
    news: store.news.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNews: () => dispatch(fetchNews()),
    updateNews: (news) => dispatch(updateNews(news)),
    deleteNews: (news) => dispatch(deleteNews(news)),
    createNews: (news) => dispatch(createNews(news)),
    clearError: () => dispatch(clearErrors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsContainer);
