import User from "../core/User";

export default {
  user: {
    isLoading: false,
    user: new User(),
  },
  indicators: {
    isLoading: false,
    error: null,
    data: [],
  },
  regions: {
    isLoading: false,
    error: null,
    data: []
  },
  agencies: {
    isLoading: false,
    error: null,
    data: []
  },
  news: {
    isLoading: false,
    error: null,
    data: []
  },
  documents: {
    isLoading: false,
    error: null,
    data: []
  },
  equipments: {
    isLoading: false,
    error: null,
    data: []
  },
  epis: {
    isLoading: false,
    error: null,
    data: []
  },

}