import React, { Component } from 'react';
import 'react-vis/dist/style.css';
import PropTypes from 'prop-types';
import { Layout, Breadcrumb, Col, Row, Table, Icon, notification } from 'antd';
import Domain from '../../../core/Domain';
import { EyeOutlined } from '@ant-design/icons';

const { Content } = Layout;

class Dashboard extends Component {
  static propTypes = {
    stats: PropTypes.object,
    fetchDocuments: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    documents: PropTypes.array.isRequired,
    error: PropTypes.string,
    clearError: PropTypes.func.isRequired,
  };

  state = {
    colorsDomain: [
      '#ED3833',
      '#2070C0',
      '#92D050',
      '#51B052',
      '#9DC3E6',
      '#EE6277',
    ],
    colorRange: [1, 6],
    documents: [],
    isLoading: false,
  };

  componentDidMount() {
    this.props.fetchDocuments();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      notification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });
      this.props.clearError();
    } else if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    } else if (nextProps.documents !== this.props.documents) {
      this.setState({ documents: nextProps.documents });
    }
  }

  mostReadDocument() {
    const tableModel = [
      {
        title: 'Domaine',
        dataIndex: 'domain',
        render: (v) => (
          <React.Fragment>
            <img src={Domain.getIcon(v).default} style={{ width: 30 }} />
          </React.Fragment>
        ),
      },
      {
        title: 'Titre',
        dataIndex: 'title',
      },
      {
        title: 'Ouverture',
        dataIndex: 'readCount',
        render: (v) => (
          <React.Fragment>
            <EyeOutlined /> {v}
          </React.Fragment>
        ),
      },
    ];

    return (
      <React.Fragment>
        <h2> Documents les plus lus</h2>
        <Table
          sortDirections={['descend', 'ascend']}
          loading={this.state.isLoading}
          columns={tableModel}
          dataSource={this.state.documents}
          rowKey={'id'}
          pagination={false}
        />
      </React.Fragment>
    );
  }

  rapport() {
    return (
      <React.Fragment>
        <h2> B.I.</h2>
        <iframe
          src={
            'https://rapports.dbcall.fr/?module=Embed&OBJID=B6FD59008733E42EE6983832D0AA2A2A&user=' +
            'userdemo@dbcall.fr'
          }
          height={500}
          width={1000}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <h1>Dashboard</h1>

          <Row>
            <Col xl={24} md={24}>
              {this.rapport()}
            </Col>
          </Row>

          <Row style={{ marginTop: 24 }} md={24}>
            <Col xl={24} md={24}>
              {this.mostReadDocument()}
            </Col>
          </Row>
        </div>
      </Content>
    );
  }
}

export default Dashboard;
