import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  Layout,
  Breadcrumb,
  Button,
  Modal,
  Select,
  DatePicker,
  Table,
  Input,
  notification,
} from 'antd';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/fr_FR';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Indicator from '../../../core/Indicators';
const { Content } = Layout;

moment.locale('fr');

export default class IndicatorsPage extends Component {
  static propTypes = {
    getIndicators: PropTypes.func.isRequired,
    createIndicator: PropTypes.func.isRequired,
    updateIndicator: PropTypes.func.isRequired,
    deleteIndicator: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired,
    indicators: PropTypes.array,
    regions: PropTypes.array,
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    clearError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalVisible: false,
      indicators: [],
      currentIndicator: new Indicator(),
      currentAction: 'CREATE',
      regions: [],
    };
    this.tableModel = [
      {
        title: '#',
        dataIndex: 'id',
      },
      {
        title: 'Indicateur',
        dataIndex: 'key',
        render: (v, row) => (
          <React.Fragment>
            <img
              src={Indicator.getIcon(v)}
              align={`Icon for ${v}`}
              style={{ marginRight: 12 }}
            />{' '}
            {Indicator.getName(v, row.name)}
          </React.Fragment>
        ),
      },
      {
        title: 'Region',
        dataIndex: 'region',
        render: (region) => {
          const name = `${region && region.name ? region.name : ''} (${
            region && region.code ? region.code : ''
          })`;
          return <React.Fragment>{name}</React.Fragment>;
        },
      },
      {
        title: 'Dernière occurence',
        key: 'lastOccurrence',
        dataIndex: 'lastOccurrence',
        render: (date) => {
          return (
            <React.Fragment>
              {date.format('LL')} ({moment().diff(date, 'days')} jours)
            </React.Fragment>
          );
        },
      },
      {
        title: 'Actions',
        key: 'x',
        render: (v, row) => (
          <React.Fragment>
            <Button
              loading={this.state.loading}
              onClick={() => {
                this.setState({
                  currentIndicator: new Indicator(
                    row.id,
                    row.name,
                    row.key,
                    row.lastOccurrence,
                    row.region.code,
                  ),
                  modalVisible: true,
                  currentAction: 'UPDATE',
                });
              }}
              icon={<EditOutlined />}
              type={'primary'}
              style={{ marginRight: 12 }}
            >
              Editer
            </Button>

            <Button
              onClick={() => {
                this.setState(
                  {
                    currentIndicator: new Indicator(
                      row.id,
                      row.name,
                      row.key,
                      row.lastOccurrence,
                      row.region.code,
                    ),
                  },
                  () => this.deleteIndicator(),
                );
              }}
              loading={this.state.loading}
              icon={<DeleteOutlined />}
              type={'danger'}
            >
              {' '}
              Supprimer
            </Button>
          </React.Fragment>
        ),
      },
    ];

    this.indicatorModal = this.indicatorModal.bind(this);
    this.changeIndicatorValue = this.changeIndicatorValue.bind(this);
    this.updateIndicator = this.updateIndicator.bind(this);
    this.deleteIndicator = this.deleteIndicator.bind(this);
  }

  async componentDidMount() {
    this.props.getIndicators();
    this.props.getRegions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      notification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });
      this.props.clearError();
    } else if (nextProps.indicators !== this.props.indicators) {
      this.setState({ indicators: nextProps.indicators, modalVisible: false });
    } else if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({ loading: nextProps.isLoading });
    } else if (nextProps.error !== this.props.error) {
      this.setState({ error: nextProps.error });
    }
    if (nextProps.regions !== this.props.regions) {
      this.setState({ regions: nextProps.regions });
    }
  }

  deleteIndicator() {
    this.props.deleteIndicator(this.state.currentIndicator);
  }

  changeIndicatorValue(type, value) {
    const currentIndicator = this.state.currentIndicator;

    switch (type) {
      case Indicator.fields.key:
        currentIndicator.key = value;
        currentIndicator.name = Indicator.getName(value, '');
        break;
      case Indicator.fields.name:
        currentIndicator.name = value;
        break;
      case Indicator.fields.regionCode:
        const region = _.find(this.state.regions, { code: value });
        currentIndicator.region = region;
        currentIndicator.regionCode = region.code;
        break;
      case Indicator.fields.lastOccurence:
        currentIndicator.lastOccurrence = value;
        break;
    }

    this.setState({ currentIndicator });
  }

  updateIndicator() {
    if (this.state.currentAction === 'CREATE') {
      this.props.createIndicator(this.state.currentIndicator);
    } else if (this.state.currentAction === 'UPDATE') {
      this.props.updateIndicator(this.state.currentIndicator);
    }
  }

  indicatorModal() {
    return (
      <Modal
        title={
          this.state.currentAction === 'CREATE'
            ? 'Ajouter un indicateur'
            : 'Modifier un indicateur'
        }
        visible={this.state.modalVisible}
        okText={'Valider'}
        cancelText={'Annuler'}
        footer={
          <React.Fragment>
            <Button
              type={'default'}
              onClick={() =>
                this.setState({ modalVisible: !this.state.modalVisible })
              }
            >
              {' '}
              Annuler{' '}
            </Button>
            <Button
              type={'primary'}
              loading={this.state.loading}
              onClick={() => this.updateIndicator()}
            >
              {' '}
              {this.state.currentAction === 'CREATE'
                ? 'Ajouter'
                : 'Mettre à jour'}{' '}
            </Button>
          </React.Fragment>
        }
        onCancel={() =>
          this.setState({ modalVisible: !this.state.modalVisible })
        }
      >
        <Select
          style={{ minWidth: 300 }}
          placeholder={'Indicateur'}
          value={this.state.currentIndicator.key}
          onChange={(value) =>
            this.changeIndicatorValue(Indicator.fields.key, value)
          }
        >
          {Indicator.list().map((indicator) => {
            return (
              <Select.Option key={indicator} value={indicator}>
                <img
                  style={{ height: 20, marginRight: 12 }}
                  src={Indicator.getIcon(indicator)}
                />
                {Indicator.getName(indicator, 'Autre')}
              </Select.Option>
            );
          })}
        </Select>

        {this.state.currentIndicator.key === 'IND_OTHER' ? (
          <Input
            value={this.state.currentIndicator.name}
            style={{ maxWidth: 300, marginTop: 12 }}
            placeholder={"Nom de l'indicateur"}
            onChange={(e) =>
              this.changeIndicatorValue(Indicator.fields.name, e.target.value)
            }
          />
        ) : null}

        <Select
          style={{ minWidth: 300, marginTop: 20 }}
          placeholder={'Région'}
          value={this.state.currentIndicator.region.code}
          onChange={(value) =>
            this.changeIndicatorValue(Indicator.fields.regionCode, value)
          }
        >
          {this.state.regions.map((region) => (
            <Select.Option key={region.code} value={region.code}>
              {region.name} ({region.code})
            </Select.Option>
          ))}
        </Select>

        <br />
        <DatePicker
          disabledDate={(date) => {
            const now = moment();
            return now.isBefore(date);
          }}
          format={'LL'}
          locale={locale}
          style={{ minWidth: 300, marginTop: 20 }}
          value={this.state.currentIndicator.lastOccurrence}
          placeholder={'Dernière occurence'}
          onChange={(value) =>
            this.changeIndicatorValue(Indicator.fields.lastOccurence, value)
          }
        />
      </Modal>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Indicateurs</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <h1>Indicateurs</h1>

          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              this.setState({
                modalVisible: !this.state.modalVisible,
                currentAction: 'CREATE',
                currentIndicator: new Indicator(),
              });
            }}
          >
            Ajouter un indicateur
          </Button>

          <Table
            style={{ marginTop: 24 }}
            columns={this.tableModel}
            dataSource={this.state.indicators}
            rowKey="id"
          />
        </div>
        {this.indicatorModal()}
      </Content>
    );
  }
}
