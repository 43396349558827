import { CLEAR_ERROR, DOCUMENTS } from "../constants/actionsTypes";
import * as _ from "lodash";


export default function(state = {}, action) {

  const data = _.cloneDeep(state.data);

  switch (action.type) {

    case DOCUMENTS.FETCH:
      return {...state, isLoading: action.isLoading};

    case DOCUMENTS.LIST.SUCCESS:
      return {...state, error: null, data: action.documents};

    case DOCUMENTS.DELETE.SUCCESS:
      _.remove(data, {id: action.documentId});
      return {...state, error:null, data};

    case DOCUMENTS.CREATE.SUCCESS:
      data.push(action.document);
      return {...state, error:null, data};

    case DOCUMENTS.UPDATE.SUCCESS:

      _.remove(data, {id: action.news.id});
      data.push(action.news);

      return {...state, error:null, data};

    case DOCUMENTS.LIST.FAILED:
    case DOCUMENTS.DELETE.FAILED:
    case DOCUMENTS.CREATE.FAILED:
    case DOCUMENTS.UPDATE.FAILED:
      return {...state, error:action.error, data: []};

    case CLEAR_ERROR :
      return {...state, error: null};

    default:
      return state;
  }

}
