// @flow

import {USER} from "../constants/actionsTypes";
import User from "../core/User";

/*
*   LOGIN
*/

const loginStart = (loading) => {
  return {
    type: USER.LOGIN.FETCH,
    loading,
  }
};

const loginSuccedded = (user) => {
  return {
    type: USER.LOGIN.SUCCESS,
    user,
  }
};

const loginFailed =  (error) => {
  return {
    type: USER.LOGIN.FAILED,
    error,
  }
};

const login = (user) => {

 return async (dispatch) => {

   dispatch(loginStart(true));
   try {
     const logguedUser = await user.login();
     dispatch(loginSuccedded(logguedUser));
   } catch (e) {
     dispatch(loginFailed(e.message));
   } finally {
     dispatch(loginStart(false));
   }

 }

};

export {login}
