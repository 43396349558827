import {EPI as EPIAction} from "../constants/actionsTypes";
import EPI from '../core/EPI'

const fetch = (isLoading) => {
  return {
    type: EPIAction.FETCH,
    isLoading,
  }
};

const listSuccess = (epis) => {
  return {
    type: EPIAction.LIST.SUCCESS,
    epis,
  }
};

const listError = (error) => {
  return {
    type: EPIAction.LIST.FAILED,
    error,
  }
};

const listEpis = () => {
  return async (dispatch) => {
    dispatch(fetch(true));

    try {
      const epis = await EPI.getAll();
      dispatch(listSuccess(epis));
    } catch(error) {
      dispatch(listError(error.message))
    } finally {
      dispatch(fetch(false));
    }
  }
};

/* CREATE */

const createSuccess = (epi) => {
  return {
    type: EPIAction.CREATE.SUCCESS,
    epi,
  }
};

const createError = (error) => {
  return {
    type: EPIAction.CREATE.FAILED,
    error,
  }
};

const createEpi = (epi) => {
  return async (dispatch) => {
    dispatch(fetch(true));

    try {
      const newEpi = await epi.create();
      dispatch(createSuccess(newEpi));
    } catch(error) {
      dispatch(createError(error.message))
    } finally {
      dispatch(fetch(false));
    }
  }
};

/* DELETE */

const deleteSuccess = (epiId) => {
  return {
    type: EPIAction.DELETE.SUCCESS,
    epiId,
  }
};

const deleteError = (error) => {
  return {
    type: EPIAction.DELETE.FAILED,
    error,
  }
};

const deleteEpi = (epi) => {
  return async (dispatch) => {
    dispatch(fetch(true));

    try {
      await epi.delete();
      dispatch(deleteSuccess(epi.id));
    } catch(error) {
      dispatch(deleteError(error.message))
    } finally {
      dispatch(fetch(false));
    }
  }
};

/* DELETE */

const updateSuccess = (epi) => {
  return {
    type: EPIAction.UPDATE.SUCCESS,
    epi,
  }
};

const updateError = (error) => {
  return {
    type: EPIAction.UPDATE.FAILED,
    error,
  }
};

const updateEpi = (epi) => {
  return async (dispatch) => {
    dispatch(fetch(true));

    try {
      await epi.update();
      dispatch(updateSuccess(epi));
    } catch(error) {
      dispatch(updateError(error.message))
    } finally {
      dispatch(fetch(false));
    }
  }
};


export {listEpis, createEpi, deleteEpi, updateEpi}

