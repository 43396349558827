import React, { Component } from "react";
import { connect } from "react-redux";
import IndicatorsPage from "../components/Views/Indicators";
import { getIndicators, createIndicator, updateIndicator, deleteIndicator } from "../actions/indicators.actions";
import { listRegions } from "../actions/regions.actions";
import {clearErrors} from "../actions/error.action";

class IndicatorsContainer extends Component {
  render() {
    return (
      <IndicatorsPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    indicators: store.indicators.data,
    error: store.indicators.error,
    isLoading: store.indicators.isLoading,
    regions: store.regions.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIndicators: () => dispatch(getIndicators()),
    getRegions: () => dispatch(listRegions()),
    createIndicator: (indicator) => dispatch(createIndicator(indicator)),
    updateIndicator: (indicator) => dispatch(updateIndicator(indicator)),
    deleteIndicator: (indicator) => dispatch(deleteIndicator(indicator)),
    clearError: () => dispatch(clearErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorsContainer);
