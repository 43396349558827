export default class Domain {

  constructor(name, icons, color, key, id) {
    this.name = name;
    this.icons = icons;
    this.color = color;
    this.key = key;
    this.id = id
  }

  static getFromId(id: number) : Domain {

    switch (id) {
      case 2: return Standards;
      case 3: return Environment;
      case 4: return Health;
      case 5: return Consulting;
      case 6: return Qualite;
      case 7: return Radioprotection;

    }
  }

  static getIcon(id: number) {

    switch (id) {
      case 2: return require('../assets/domaines/norms@3x.png');
      case 3: return require('../assets/domaines/environnement@3x.png');
      case 4: return require('../assets/domaines/security@3x.png');
      case 5: return require('../assets/domaines/advice@3x.png');
      case 6: return require('../assets/domaines/quality@3x.png');
      case 7: return require('../assets/domaines/radioprotection@3x.png');
    }
  }
}


const Standards = new Domain('Normes', require('../assets/domaines/norms@3x.png').default, '#2070C0', '@normes', 2);
const Environment = new Domain('Environnement', require('../assets/domaines/environnement@3x.png').default, '#92D050', '@environment', 3);
const Health = new Domain('Santé & Secu.', require('../assets/domaines/security@3x.png').default, '#ED3833', '@sante', 4);
const Consulting = new Domain('Conseil', require('../assets/domaines/advice@3x.png').default, '#9DC3E6', '@conseil', 5);
const Qualite = new Domain('Qualité', require('../assets/domaines/quality@3x.png').default, '#EE6277', '@qualite', 6);
const Radioprotection = new Domain('Radioprotection', require('../assets/domaines/radioprotection@3x.png').default, '#FDA50F', '@radioprotection', 7);

export {Standards, Environment, Health, Consulting, Qualite, Radioprotection};


