import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import {
  Layout,
  Breadcrumb,
  Button,
  Modal,
  Table,
  Input,
  Tag,
  Select,
  notification,
  Checkbox,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import Document from '../../../core/Documents';
import Domain from '../../../core/Domain';

const { Content } = Layout;
const { Option } = Select;

class DocumentsPage extends Component {
  static propTypes = {
    fetchDocuments: PropTypes.func.isRequired,
    createDocument: PropTypes.func.isRequired,
    updateDocument: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    documents: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  state = {
    addDocumentModalOpened: false,
    currentDocument: new Document(),
    documents: [],
    currentAction: '',
    tagsString: '',
    isLoading: false,
  };

  tableModel = [
    {
      title: 'Domaine',
      dataIndex: 'domain',
      sorter: (a, b) => {
        return a.domain - b.domain;
      },
      render: (v) => {
        const domain = Domain.getFromId(v);
        return (
          <Fragment>
            <img src={Domain.getIcon(v).default} style={{ width: 35 }} />{' '}
            <span style={{ color: domain.color, fontWeight: 'bold' }}>
              {domain.name}
            </span>
          </Fragment>
        );
      },
    },
    {
      title: 'Titre',
      dataIndex: 'title',
    },
    {
      title: 'Lien',
      dataIndex: 'url',
      render: (v) => (
        <Fragment>
          <a href={v}>
            {' '}
            <LinkOutlined /> Consulter{' '}
          </a>
        </Fragment>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (v) =>
        v.map((t) => <Tag key={Math.random().toString()}>{t}</Tag>),
    },
    {
      title: 'Actions',
      render: (doc) => (
        <Fragment>
          <Button
            type={'primary'}
            icon={<EditOutlined />}
            style={{ marginRight: 12 }}
            onClick={() => {
              this.setState({
                currentDocument: doc,
                currentAction: 'UPDATE',
                addDocumentModalOpened: true,
              });
            }}
          >
            Modifier
          </Button>
          <Button
            type={'danger'}
            icon={<DeleteOutlined />}
            onClick={() => {
              this.setState(
                { currentDocument: doc, currentAction: 'DELETE' },
                () => this.deleteDocument(),
              );
            }}
          >
            Supprimer
          </Button>
        </Fragment>
      ),
    },
  ];

  // View lifecycle
  componentDidMount() {
    this.props.fetchDocuments();
  }

  deleteDocument() {
    this.props.deleteDocument(this.state.currentDocument);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error) {
      notification.error({
        message: 'Une erreur est apparue',
        description: nextProps.error,
      });
    } else if (nextProps.documents !== this.props.documents) {
      let description = null;

      switch (this.state.currentAction) {
        case 'CREATE':
          description = `Document créé avec succès`;
          break;
        case 'UPDATE':
          description = `Document mis à jour avec succès`;
          break;
        case 'DELETE':
          description = `Document supprimé avec succès`;
          break;
        default:
          break;
      }

      if (description)
        notification.success({
          message: 'Action effectuée !  👌🏻',
          description,
        });

      this.setState({
        documents: _.cloneDeep(nextProps.documents),
        addDocumentModalOpened: false,
        currentAction: '',
      });
    } else if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }
  }

  analyseTags(e) {
    const value = e.target.value;

    if (value.charAt(value.length - 1) === ';') {
      const currentDocument = this.state.currentDocument;
      currentDocument.tags.push(value.slice(0, -1));
      this.setState({ tagsString: '', currentDocument });
    } else {
      this.setState({ tagsString: e.target.value });
    }
  }

  removeTag(tag) {
    const currentDocument = this.state.currentDocument;

    if (currentDocument.tags.length === 1) {
      currentDocument.tags = [];
    } else {
      _.pull(currentDocument.tags, tag);
    }

    this.setState({ currentDocument });
  }

  changeInput(type, value) {
    const currentDocument = this.state.currentDocument;

    switch (type) {
      case 'TITLE':
        currentDocument.title = value;
        break;
      case 'URL':
        currentDocument.url = value;
        break;
      case 'DOMAIN':
        currentDocument.domain = value;
        break;

      case 'CONTENT':
        currentDocument.content = value;
        break;

      case 'PUSH':
        currentDocument.sendPush = value;
        break;
    }

    this.setState({ currentDocument });
  }

  createOrUpdateDocument() {
    switch (this.state.currentAction) {
      case 'CREATE':
        this.props.createDocument(this.state.currentDocument);
        break;
      case 'UPDATE':
        this.props.updateDocument(this.state.currentDocument);
        break;
    }
  }

  addDocumentModal() {
    const domains = [2, 3, 4, 5, 6, 7];

    return (
      <Modal
        title={'Ajouter un document'}
        okText={
          this.state.currentAction === 'CREATE' ? 'Ajouter' : 'Mettre à jour'
        }
        visible={this.state.addDocumentModalOpened}
        onCancel={() =>
          this.setState({
            currentDocument: new Document(),
            currentAction: 'CREATE',
            addDocumentModalOpened: false,
          })
        }
        onOk={() => this.createOrUpdateDocument()}
        confirmLoading={this.state.isLoading}
      >
        <Select
          style={{ width: 180, height: 50 }}
          placeholder={'Domaine du document'}
          value={this.state.currentDocument.domain}
          onChange={(value) => this.changeInput('DOMAIN', value)}
        >
          {domains.map((domainId) => {
            const domain = Domain.getFromId(domainId);
            return (
              <Option value={domain.id} key={domain.key}>
                {' '}
                <img
                  style={{ width: 30, height: 30 }}
                  src={domain.icons}
                />{' '}
                {domain.name}
              </Option>
            );
          })}
        </Select>

        <Input
          style={{ marginTop: 24 }}
          placeholder={'Titre du document'}
          value={this.state.currentDocument.title}
          onChange={(e) => this.changeInput('TITLE', e.target.value)}
        />
        <Input.TextArea
          autosize={{ minRows: 5 }}
          style={{ marginTop: 24 }}
          placeholder={'Corps du document'}
          value={this.state.currentDocument.content}
          onChange={(e) => this.changeInput('CONTENT', e.target.value)}
        />
        <Input
          style={{ marginTop: 24 }}
          placeholder={'URL Adobe Spark'}
          value={this.state.currentDocument.url}
          onChange={(e) => this.changeInput('URL', e.target.value)}
        />
        <Input
          style={{ marginTop: 24 }}
          value={this.state.tagsString}
          onChange={(e) => {
            this.analyseTags(e);
          }}
          placeholder={"Tags (séparés par ';')"}
        />
        {this.state.currentDocument.tags.map((tag) => (
          <Tag
            key={`tag_${tag}_${Math.random().toString()}`}
            closable
            onClose={() => this.removeTag(tag)}
            style={{ fontSize: 16, marginTop: 12 }}
            color={'#108ee9'}
          >
            {tag}
          </Tag>
        ))}

        <br />
        <Checkbox
          checked={this.state.currentDocument.sendPush}
          onChange={(e) => this.changeInput('PUSH', e.target.checked)}
          disabled={this.state.currentAction !== 'CREATE'}
          style={{ marginTop: 12 }}
        >
          Envoyer une notification push
        </Checkbox>
      </Modal>
    );
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Documents</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <h1>Documents</h1>
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              this.setState({
                addDocumentModalOpened: !this.state.addDocumentModalOpened,
                currentAction: 'CREATE',
                currentDocument: new Document(),
              })
            }
          >
            Ajouter un document
          </Button>

          <Table
            style={{ marginTop: 24 }}
            columns={this.tableModel}
            dataSource={this.state.documents}
            rowKey={'id'}
          />
        </div>
        {this.addDocumentModal()}
      </Content>
    );
  }
}

export default DocumentsPage;
