import { cloneDeep, remove } from 'lodash';
import { CLEAR_ERROR, INDICATORS } from '../constants/actionsTypes';

export default (state = {}, action) => {
  const data = cloneDeep(state.data);

  switch (action.type) {
    case INDICATORS.FETCH:
      return { ...state, isLoading: action.isLoading };

    case INDICATORS.LIST.SUCCESS:
      return { ...state, error: null, data: action.indicators };

    case INDICATORS.CREATE.SUCCESS:
      data.push(action.indicator);
      return { ...state, error: null, data };

    case INDICATORS.UPDATE.SUCCESS:
      remove(data, { id: action.indicator.id });
      data.push(action.indicator);
      return { ...state, error: null, data };

    case INDICATORS.DELETE.SUCCESS:

      _.remove(data, { id: action.indicatorId });
      return { ...state, error: null, data };

    case INDICATORS.LIST.FAILED:
    case INDICATORS.CREATE.FAILED:
    case INDICATORS.UPDATE.FAILED:
    case INDICATORS.DELETE.FAILED:
      return { ...state, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: null };

    default:
      return state;
  }
};
