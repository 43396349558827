import React, { Component } from "react";
import { connect } from "react-redux";
import {login} from "../actions/users.actions";


import AuthenticationScreen from '../components/Views/Authentication'

const noop = () => {
};

class AuthenticationContainer extends Component {
  render() {
    return (
      <AuthenticationScreen {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user.user,
    error: store.user.error,
    isLoading: store.user.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    method2: () => dispatch(noop())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer);
