import { cloneDeep, remove } from 'lodash';
import { CLEAR_ERROR, REGIONS } from '../constants/actionsTypes';

export default (state = {}, action) => {
  const data = cloneDeep(state.data);

  switch (action.type) {
    case REGIONS.FETCH:
      return { ...state, isLoading: action.isLoading };

    case REGIONS.LIST.SUCCESS:
      return { ...state, data: action.regions, error: null };

    case REGIONS.CREATE.SUCCESS:
      data.push(action.region);
      return { ...state, data, error: null };

    case REGIONS.UPDATE.SUCCESS:
      remove(data, { id: action.region.id });
      data.push(action.region);
      return { ...state, data, error: null };

    case REGIONS.DELETE.SUCCESS:
      remove(data, { id: action.regionId });
      return { ...state, data, error: null };

    case REGIONS.LIST.FAILED:
    case REGIONS.CREATE.FAILED:
    case REGIONS.UPDATE.FAILED:
      return { ...state, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: null };

    default:
      return state;
  }
};
