import React, { FC } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import moment from 'moment';

import Authentication from '../../containers/AuthenticationContainer';
import PrivateRoute from '../Shared/PrivateRoute';
import DashboardContainer from '../../containers/DashboardContainer';
import NewsContainer from '../../containers/NewsContainer';
import IndicatorsContainer from '../../containers/IndicatorsContainer';
import UserContainer from '../../containers/UserContainer';
import DocumentsContainer from '../../containers/DocumentsContainer';
import EquipmentContainer from '../../containers/EquipmentContainer';
import NotificationsContainer from '../../containers/NotificationsContainer';
import AgenciesContainer from '../../containers/AgenciesContainer';
import ApplicationContainer from '../../containers/ApplicationContainer';
import RegionContainer from '../../containers/RegionContainer';

const isAuth = () => {
  const jwtToken = window.localStorage.getItem('@DBCall:ApiToken');

  if (jwtToken) {
    const token = jwt.decode(jwtToken);
    if (token) {
      const expiry = moment.unix(token.exp);
      return (
        (token.isExpert || token.isSuperExpert || token.isAdmin) &&
        moment().isBefore(expiry)
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const App: FC<never> = () => {
  const routes = () => {
    return (
      <div>
        <Switch>
          <Route exact path="/login" component={Authentication} />
          <ApplicationContainer>
            <PrivateRoute
              exact
              path={'/dashboard'}
              component={DashboardContainer}
            />
            <PrivateRoute
              exact
              path={'/documents'}
              component={DocumentsContainer}
            />
            <PrivateRoute exact path={'/news'} component={NewsContainer} />
            <PrivateRoute exact path={'/users'} component={UserContainer} />
            <PrivateRoute
              exact
              path={'/agencies'}
              component={AgenciesContainer}
            />
            <PrivateRoute exact path={'/regions'} component={RegionContainer} />
            <PrivateRoute
              exact
              path={'/indicators'}
              component={IndicatorsContainer}
            />
            <PrivateRoute
              exact
              path={'/equipements'}
              component={EquipmentContainer}
            />
            <PrivateRoute
              exact
              path={'/notifications'}
              component={NotificationsContainer}
            />
          </ApplicationContainer>
          <Redirect from={'/'} to={'/dashboard'} />
        </Switch>
      </div>
    );
  };

  return <BrowserRouter>{routes()}</BrowserRouter>;
};

export default App;
