import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../components/Views/Dashboard";
import {fetchDocuments} from "../actions/documents.actions";
import {clearErrors} from "../actions/error.action";


class DashboardContainer extends Component {
  render() {
    return (
      <Dashboard {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    stats: store.stats,
    documents: store.documents.data,
    isLoading: store.documents.isLoading,
    error: store.documents.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocuments: () => dispatch(fetchDocuments(true)),
    clearError: () => dispatch(clearErrors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
