import React, { Component } from 'react';
import { connect } from 'react-redux';
import GlobalLayout from '../components/Views/Application';

class ApplicationContainer extends Component {
  render() {
    return <GlobalLayout {...this.props} />;
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user.user,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationContainer);
