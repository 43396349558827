import {INDICATORS} from "../constants/actionsTypes";
import Indicator from "../core/Indicators";

const fetchIndicators = (isLoading) => {
  return {
    type: INDICATORS.FETCH,
    isLoading
  }
};


const fetchIndicatorsSuccess = (indicators) => {
  return {
    type: INDICATORS.LIST.SUCCESS,
    indicators
  }
};

const fetchIndicatorsFailure = (error) => {
  return {
    type: INDICATORS.LIST.FAILED,
    error
  }
};

const createIndicatorSuccess = (indicator) => {
  return {
    type: INDICATORS.CREATE.SUCCESS,
    indicator
  }
};

const createIndicatorFailure = (error) => {
  return {
    type: INDICATORS.CREATE.FAILED,
    error
  }
};

const updateIndicatorSuccess = (indicator) => {
  return {
    type: INDICATORS.UPDATE.SUCCESS,
    indicator
  }
};

const updateIndicatorFailure = (error) => {
  return {
    type: INDICATORS.UPDATE.FAILED,
    error
  }
};

const deleteIndicatorSuccess = (indicatorId) => {
  return {
    type: INDICATORS.DELETE.SUCCESS,
    indicatorId
  }
};

const deleteIndicatorFailure = (error) => {
  return {
    type: INDICATORS.DELETE.FAILED,
    error
  }
};


const getIndicators = () => {

  return async (dispatch) => {
    dispatch(fetchIndicators(true));
    try {
      const indicators = await Indicator.getForEnterprise();
      dispatch(fetchIndicatorsSuccess(indicators));
    } catch(error) {
      dispatch(fetchIndicatorsFailure(error.message));
    } finally {
      dispatch(fetchIndicators(false));
    }
  }
};

const createIndicator = (indicator) => {
  return async (dispatch) => {
    dispatch(fetchIndicators(true));
    try {
      const createdIndicator = await indicator.create();
      dispatch(createIndicatorSuccess(createdIndicator));
    } catch(error) {
      dispatch(createIndicatorFailure(error.message));
    } finally {
      dispatch(fetchIndicators(false));
    }
  }
};

const updateIndicator = (indicator) => {
  return async (dispatch) => {
    dispatch(fetchIndicators(true));
    try {
      const updatedIndicator = await indicator.update();
      dispatch(updateIndicatorSuccess(updatedIndicator));
    } catch(error) {
      dispatch(updateIndicatorFailure(error.message));
    } finally {
      dispatch(fetchIndicators(false));
    }
  }
};

const deleteIndicator = (indicator) => {
  return async (dispatch) => {
    dispatch(fetchIndicators(true));
    try {
      await indicator.delete();
      dispatch(deleteIndicatorSuccess(indicator.id));
    } catch(error) {
      dispatch(deleteIndicatorFailure(error.message));
    } finally {
      dispatch(fetchIndicators(false));
    }
  }
};

export {getIndicators, createIndicator, updateIndicator, deleteIndicator};
