import React, { Component } from "react";
import { connect } from "react-redux";
import UsersPage from "../components/Views/Users";
import {clearErrors} from "../actions/error.action";


class UsersContainer extends Component {
  render() {
    return (
      <UsersPage {...this.props}/>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    users: store.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => dispatch(clearErrors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
