import React from 'react';

export const Dashboard = () => (
  <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.625)">
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="#FFF"
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M19 21h-3c-1.11 0-2-.9-2-2v-5c0-1.11.89-2 2-2h3c1.1 0 2 .89 2 2v5c0 1.1-.9 2-2 2Z" />
        <path d="M19 9h-3c-1.11 0-2-.9-2-2V5c0-1.105.89-2 2-2h3c1.1 0 2 .895 2 2v2c0 1.1-.9 2-2 2Z" />
        <path d="M5 3h3c1.1 0 2 .895 2 2v5c0 1.1-.9 2-2 2H5c-1.105 0-2-.9-2-2V5c0-1.105.895-2 2-2Z" />
        <path d="M5 15h3c1.1 0 2 .89 2 2v2c0 1.1-.9 2-2 2H5c-1.105 0-2-.9-2-2v-2c0-1.11.895-2 2-2Z" />
      </g>
      <path fill="none" d="M0 0h24v24H0Z" />
    </g>
  </svg>
);

const style = {
  fill: 'none',
  stroke: '#fff',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
};
export const Indicators = () => (
  <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.625)">
      <g fill="none">
        <path d="M0 0h24v24H0Z" />
        <g
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#FFF"
          fill="none"
          strokeLinejoin="round"
        >
          <path d="M12 3.44v2" />
          <path d="M21 12h-2" />
          <path d="M3 12h2" />
          <path d="M18.36 5.64l-2.12 2.12" />
          <path d="M5.64 18.36l2-2" />
          <path d="M18.36 18.36l-2.12-2.12" />
          <path d="M5.64 5.64l2.12 2.12" />
          <path d="M18.364 5.63c3.51 3.51 3.51 9.21 0 12.72 -3.52 3.51-9.22 3.51-12.73 0 -3.52-3.52-3.52-9.22-.01-12.73 3.51-3.52 9.21-3.52 12.72-.01" />
          <path
            strokeWidth="1.499"
            d="M10.54 11.042v0c-.76.75-.76 1.97 0 2.73v0c.75.75 1.97.75 2.73 0v0 -.01 0c.85-.86.84-2.692.81-3.324 -.01-.13-.11-.23-.24-.24 -.64-.03-2.48-.04-3.324.81"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const News = () => (
  <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.625)">
      <g fill="none">
        <path d="M0 0h24v24H0Z" />
        <g
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#FFF"
          fill="none"
          strokeLinejoin="round"
        >
          <path d="M18.01 18.9L4.78 14.52v0c-.77-.26-1.3-.97-1.3-1.78v-2.5h-.01c0-.81.52-1.53 1.29-1.78l13.22-4.38h-.01c.98-.34 2.05.2 2.38 1.18 .06.18.09.38.09.58v11.24 -.01c-.01 1.04-.86 1.88-1.9 1.87 -.2-.01-.4-.04-.59-.1Z" />
          <path d="M8.31 15.692v-.01c-.21.4-.31.85-.32 1.3v0c.01 1.65 1.36 2.99 3.02 2.97 1.42-.01 2.64-1.03 2.92-2.43" />
          <path d="M8.31 15.69l5.63 1.87" />
        </g>
      </g>
    </g>
  </svg>
);

export const Document = () => (
  <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.625)">
      <g fill="none">
        <path d="M0 0h24v24H0Z" />
        <g
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#FFF"
          fill="none"
          strokeLinejoin="round"
        >
          <path d="M8 4l1.3-1.625c.19-.24.47-.375.78-.375h3.83c.3 0 .59.13.78.375L15.98 4v0 1c0 .55-.45 1-1 1h-6c-.56 0-1-.45-1-1V4v0Z" />
          <path d="M8 4H6c-1.11 0-2 .89-2 2v13c0 1.1.89 2 2 2h12c1.1 0 2-.9 2-2V6c0-1.11-.9-2-2-2h-2" />
          <path d="M8 11h8" />
          <path d="M8 16h8" />
        </g>
      </g>
    </g>
  </svg>
);

const logout = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path style={style} d="M1.414,16.5a11.5,11.5,0,1,0,0-9" />
    <polyline style={style} points="12.5 16 16.5 12 12.5 8" />
    <line style={style} x1="16.5" y1="12" x2="0.5" y2="12" />
  </svg>
);

export const Helmet = () => (
  <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.625)">
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="#FFF"
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M3.75 15.74v3.75 0c0 1.24 1 2.24 2.25 2.24h-.01l-.01-.01c1.24 0 2.25-1.01 2.25-2.25v-.75h7.5l-.01-.01c.94 0 1.83-.45 2.4-1.2l1.35-1.8" />
        <path d="M15.789 7.81v0c-1.48-2.08-3.87-3.32-6.42-3.33v0 -.01c-4.13.01-7.55 3.19-7.86 7.3" />
        <path d="M7.016 4.858l3.05-2.3h-.01c.27-.22.61-.33.96-.31l-.01-.01c5.1.09 9.2 4.25 9.21 9.36v4.12" />
        <path d="M12 11.24H3h-.01c-1.25 0-2.25 1-2.25 2.25 0 1.24 1 2.25 2.25 2.24h20.25" />
      </g>
    </g>
  </svg>
);

export const Users = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.833)">
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="#FFF"
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M2 19v0c0-2.2 1.8-4 4-4h4c2.2 0 4 1.8 4 4v0" />
        <path d="M10.5 6c1.4 1.4 1.4 3.6 0 4.9 -1.4 1.3-3.6 1.4-4.9 0C4.3 9.5 4.2 7.4 5.5 6c1.3-1.4 3.6-1.3 5 0" />
        <path d="M16 14h3c1.7 0 3 1.3 3 3v0" />
        <path d="M19.3 6.7c1 1 1 2.6 0 3.5 -1 .9-2.6 1-3.5 0 -.9-1-1-2.6 0-3.5 .9-.9 2.5-.9 3.5 0" />
      </g>
      <path fill="none" d="M0 0h24v24H0V0Z" />
    </g>
  </svg>
);

export const Building = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.833)">
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="#FFF"
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M4 4h10 -.01c.55-.01 1 .44 1 .99v15h-11 -.01c-.56-.01-1-.45-1-1.01v-14 0c-.01-.56.44-1.01.99-1.01Z" />
        <path d="M15 8h5 -.01c.55-.01 1 .44 1 .99v10 0c0 .55-.45 1-1 1h-5v-12Z" />
        <path d="M7 7.875h-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
        <path d="M11 7.875h-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
        <path d="M7 11.87l-.01-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
        <path d="M11 11.87h-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
        <path d="M7 15.87l-.01-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
        <path d="M11 15.87l-.01-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
        <path d="M18 11.87h-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
        <path d="M18 15.87l-.01-.01c-.07 0-.13.05-.13.12 0 .06.05.12.12.12 .06-.01.12-.06.12-.13v0c0-.07-.06-.13-.13-.125" />
      </g>
      <path fill="none" d="M0 0h24v24H0Z" />
    </g>
  </svg>
);

export const Notifications = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.833)">
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="#FFF"
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M18.5 2l-.01 0c-1.94 0-3.5 1.56-3.5 3.5 0 1.93 1.56 3.5 3.5 3.49l-.01-.001c1.93 0 3.5-1.57 3.5-3.5 0-1.94-1.57-3.5-3.5-3.51Z" />
        <path d="M19 12.73v3.82 -.01c0 2.45-1.99 4.44-4.45 4.44H7.43l-.01-.01c-2.46-.01-4.45-1.99-4.45-4.45V9.39h-.01c0-2.46 1.98-4.45 4.44-4.45h3.82" />
      </g>
      <path fill="none" d="M0 0h24v24H0Z" />
    </g>
  </svg>
);

export { logout };
