import { CLEAR_ERROR, EQUIPMENT } from "../constants/actionsTypes";

export default function(state = {}, action) {

  switch (action.type) {

    case EQUIPMENT.FETCH:
      return {...state, isLoading: action.isLoading};

    case EQUIPMENT.LIST.SUCCESS:
      return {...state, error: null, data: action.equipments};

    case EQUIPMENT.LIST.FAILED:
      return {...state, error:action.error, data: []};
    case CLEAR_ERROR :
      return {...state, error: null};

    default:
      return state;
  }

}
