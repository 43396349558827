import {AGENCIES} from "../constants/actionsTypes";
import Agency from "../core/Agency";


const loadAgencies = (isLoading) => {
  return {
    type: AGENCIES.FETCH,
    isLoading
  }

};

/* FETCH AGENCIES */

const listAgenciesSuccess = (agencies) => {
  return {
    type: AGENCIES.LIST.SUCCESS,
    agencies
  }
};

const listAgenciesFailure = (error) => {
  return {
    type: AGENCIES.LIST.FAILED,
    error
  }
};


const listAgencies = () => {
  return async (dispatch) => {
    dispatch(loadAgencies(true));

    try {
      const agencies = await Agency.getAll();
      dispatch(listAgenciesSuccess(agencies));
    } catch(error) {
      dispatch(listAgenciesFailure(error.message));
    } finally {
      dispatch(loadAgencies(false));
    }
  }
};

/* CREATE AGENCIES */

const createAgencySuccess = (agency) => {
  return {
    type: AGENCIES.CREATE.SUCCESS,
    agency
  }
};

const createAgencyError = (error) => {
  return {
    type: AGENCIES.CREATE.FAILED,
    error
  }
};

const createAgency = (agency) => {

  return async dispatch => {

    dispatch(loadAgencies(true));

    try {
      const createdAgency = await agency.create();
      dispatch(createAgencySuccess(createdAgency));

    } catch(error) {
      dispatch(createAgencyError(error.message));
    } finally {
      dispatch(loadAgencies(false));
    }

  }


};

/* UPDATE */

/* CREATE AGENCIES */

const updateAgencySuccess = (agency) => {
  return {
    type: AGENCIES.UPDATE.SUCCESS,
    agency
  }
};

const updateAgencyError = (error) => {
  return {
    type: AGENCIES.UPDATE.FAILED,
    error
  }
};

const updateAgency = (agency) => {

  return async dispatch => {

    dispatch(loadAgencies(true));

    try {
      const createdAgency = await agency.update();
      dispatch(updateAgencySuccess(createdAgency));
    } catch(error) {
      dispatch(updateAgencyError(error.message));
    } finally {
      dispatch(loadAgencies(false));
    }

  }

};

/* DELETE */

const deleteAgencySuccess = (agencyId) => {
  return {
    type: AGENCIES.DELETE.SUCCESS,
    agencyId
  }
};

const deleteAgencyError = (error) => {
  return {
    type: AGENCIES.DELETE.FAILED,
    error
  }
};

const deleteAgency = (agency) => {

  return async dispatch => {

    dispatch(loadAgencies(true));

    try {
      await agency.delete();
      dispatch(deleteAgencySuccess(agency.code));
    } catch(error) {
      dispatch(deleteAgencyError(error.message));
    } finally {
      dispatch(loadAgencies(false));
    }

  }

};

export {listAgencies, createAgency, updateAgency, deleteAgency}
